import {
  Chat,
  FormatBold,
  FormatColorFill,
  FormatColorText,
  FormatItalic,
  FormatUnderlined,
  Save,
} from "@material-ui/icons";
import { postAndStreamResponse } from "api/api-http-methods";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import ChatView from "components/ChatView";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import DocSourcesModalTriggerNew from "components/DocSourcesModalTriggerNew";
import { ChatIcon } from "components/IconsNew";
import InputExpanding from "components/InputExpanding";
import LayoutNew from "components/LayoutNew";
import { useIsDebug } from "components/ReferenceModalSpan";
import WordDocModalTrigger from "components/WordDocModalTrigger";
import ButtonWord from "components/ui/ButtonWord";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import { CrossIcon, DownloadIcon, WordIcon } from "components/ui/Icons";
import { is } from "date-fns/locale";
import useClickOutside from "hooks/useClickOutside";
import useResource from "hooks/useResource";
import { clamp, cloneDeep, range } from "lodash";
import { WIKI_TEXT } from "pages/word-docs/:wordDocId/fixtures";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  getBlocksFromContent,
  getContentFromBlocks,
} from "utils/word-conversion-utils";
import {
  HEIGHT,
  SF,
  SF_Y,
  TOP_GAP,
  VIEWPORT_WIDTH,
  VIEW_HEIGHT,
  WIDTH,
  deleteAtSelection,
  drawContent,
  getSelectionStyle,
  getSelectionStylesFromEvent,
  insertAtSelection,
  moveCaret,
  moveCaretLine,
} from "utils/word-utils-new";

const FOUR_CELLS =
  "\u0010\u0012\u001c1,1\u001c1,2 some text and mroe even more more more more\u0012\u001c2,1 lso ut a bird:\n\n Some more text here\n\nThe horned sungem (Heliactin bilophus) is a species of hummingbird native to Brazil, Bolivia and Suriname many more things\n\u001c2,2 info abo.\n\u0012\u001c1,1\u001c1,2 some text and mroe even more more more more\u0011";

const TEST_CONTENT = {
  // text: `Hello\n\nHello another line\n\nHello\n\nHello another lineHello\n\nHello another line`,
  // text: FOUR_CELLS,
  text: `\nhere\nA normal line${FOUR_CELLS} of text\nhelloThis is a prefectly normal tex${WIKI_TEXT?.slice(
    0,
    2000
  )}${FOUR_CELLS}hey there man${WIKI_TEXT?.slice(0, 500)}`,
  textStyles: [
    null,
    // {
    //   isBlock: true,
    //   type: "image",
    //   w: 300,
    //   h: 300,
    // },
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  selectionStyles: [
    // {
    //   isSelection: true,
    // },
    null,
    // { isCaret: true },
    null,
    null,
    null,
    null,
    null,
    // {
    //   isCaret: true,
    // },
    // {
    //   isSelection: false,
    // },
  ],
};

const Container = styled.div`
  position: relative;
  display: grid;
  /* justify-items: center; */
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  overflow: hidden;
  background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%);
`;

const TopBar = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
  background-color: #f3f5f7;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`;

const PulsingDot = styled.div`
  margin: 0 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const ToolBar = styled.div`
  /* position: fixed;
  top: 90px; */
  transition: transform 0.2s;

  width: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 0px;
  z-index: 1;
  background-color: white;

  box-shadow: 0px 9px 27px rgba(0, 0, 0, 0.07),
    0px 3.75998px 11.28px rgba(0, 0, 0, 0.0503198),
    0px 2.01027px 6.0308px rgba(0, 0, 0, 0.0417275),
    0px 1.12694px 3.38082px rgba(0, 0, 0, 0.035),
    0px 0.598509px 1.79553px rgba(0, 0, 0, 0.0282725),
    0px 0.249053px 0.747159px rgba(0, 0, 0, 0.0196802);
`;

const RightSideButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  width: 32px;
  height: 32px;

  gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: ${props => (props.isActive ? "#a0d4fc55" : "transparent")};
  border: none;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  :hover {
    color: #eaeaea;
    background-color: ${props => (props.isActive ? "#a0d4fc88" : "#eaeaea")};
  }
  :disabled {
    opacity: 0.2;
  }
  svg {
    height: 20px;
    width: 20px;
    fill: #000000;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 18px;
  margin: 0 8px;
  background-color: #e0e0e0;
`;

const StyledInputExpanding = styled(InputExpanding)`
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  border: none;
  background-color: transparent;
`;

const Debug = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  white-space: pre-wrap;
  border: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px;
  width: 300px;
  height: 100vh;
  overflow-y: auto;
`;

const Canvas = styled.canvas`
  /* margin-top: 0px; */
  /* border: 1px solid #e0e0e0; */
  width: ${VIEWPORT_WIDTH}px;
  height: ${VIEW_HEIGHT}px;
  border-bottom: none;
  cursor: text;
  background-color: white;
  box-shadow: 0px 4px 12.6px rgba(0, 0, 0, 0.25);
`;

const ContextMenu = styled.div`
  position: fixed;
  /* border: 1px solid #e0e0e0; */
  background-color: white;
  border-radius: 50%;
  padding: 0px;

  white-space: pre-wrap;
  box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Caret = styled.div`
  z-index: 1;
  animation: blink 1s step-end infinite;
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;

const TwoColumns = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Slideout = styled.div`
  position: relative;
  height: 100%;
  width: ${props => (props.isOpen ? "300px" : "0px")};
  transition: width 0.2s;
  box-shadow: 0px 4px 12.6px rgba(0, 0, 0, 0.25);
  background-color: white;
`;

const FixedButton = styled.div`
  position: absolute;
  transform: translateX(-100%);
  background-color: white;
  top: 20px;
  height: 40px;
  width: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
  border: 1px solid #e0e0e0;
  border-right: none;

  /* box-shadow: 0px 9px 27px rgba(0, 0, 0, 0.07),
    0px 3.75998px 11.28px rgba(0, 0, 0, 0.0503198),
    0px 2.01027px 6.0308px rgba(0, 0, 0, 0.0417275),
    0px 1.12694px 3.38082px rgba(0, 0, 0, 0.035),
    0px 0.598509px 1.79553px rgba(0, 0, 0, 0.0282725),
    0px 0.249053px 0.747159px rgba(0, 0, 0, 0.0196802); */
`;

const ProgressContainer = styled.div`
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: #e0e0e0;
  top: 48px;
  z-index: 2;
`;

const Progress = styled.div`
  transition: width 0.2s;
`;

const TopProgressBar = ({ value }) => (
  <ProgressContainer>
    <Progress
      style={{
        width: `${value}%`,
        height: "100%",
        backgroundColor: "#0191ff",
      }}
    />
  </ProgressContainer>
);

const KEY_TO_NUM_CHARS = {
  ArrowRight: 1,
  ArrowLeft: -1,
  ArrowDown: 1,
  ArrowUp: -1,
};

const INITIAL_CONTENT = {
  text: "\n\n",
  selectionStyles: [
    null,
    {
      isCaret: true,
    },
  ],
  textStyles: [],
};

let BASE_URL = "";
let IS_LOCAL_DEV = false;
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  IS_LOCAL_DEV = true;
  BASE_URL = "https://ocr.boltztest.com";
  // BASE_URL = "https://flow.boltzbit.com";
}

const WordDocArea = ({ wordDocId = "", isFillingUp = false }) => {
  const [searchParams] = useSearchParams();

  const isDebug = useIsDebug(false);
  const [wordDoc, setWordDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [abortController, setAbortController] = useState(new AbortController());
  const [isGenerating, setIsGenerating] = useState(false);
  const [promptOfGeneration, setPromptOfGeneration] = useState("");

  const [isDocFocussed, setIsDocFocussed] = useState(true);
  const [content, setContent] = useState(INITIAL_CONTENT);
  const [contentCoords, setContentCoords] = useState({});

  const [topY, setTopY] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [caretCoords, setCaretCoords] = useState({ x: 0, y: 0 });
  const [topGap, setTopGap] = useState(TOP_GAP);

  const [tempBlocks, setTempBlocks] = useState([]);

  const canvasRef = useRef(null);

  const jobId = searchParams.get("jobId");
  const [job] = useResource({
    url: `/solutions/ocr/job-service/api/v2/jobs/${jobId}`,
    pollInterval: 2000,
    stopPollIf: job => job?.status === "DONE" || job?.status === "FAILED",
  });

  let isJobInProgress =
    !!job && job?.progress !== 100 && job?.status !== "FAILED";

  useClickOutside(canvasRef, e => {
    setIsDocFocussed(false);
  });

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId, job?.progress, isFillingUp]);

  useEffect(() => {
    if (!isDocFocussed || isJobInProgress) {
      return;
    }

    document.addEventListener("paste", onPaste);
    document.addEventListener("copy", onCopy);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("paste", onPaste);
      document.removeEventListener("copy", onCopy);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [JSON.stringify(content), isDocFocussed, isJobInProgress]);

  // drawing
  useEffect(() => {
    const ctx = canvasRef?.current?.getContext("2d");
    drawContent({ content, ctx, topY, contentCoords });
  }, [JSON.stringify({ content, contentCoords }), canvasRef?.current, topY]);

  // calculating char positions
  useEffect(() => {
    const ctx = canvasRef?.current?.getContext("2d");
    const newCoords = drawContent({ content, ctx, mode: "no-paint" });
    newCoords.textLength = content?.text?.length;

    setContentCoords(newCoords);
  }, [content?.text, JSON.stringify(content?.textStyles)]);

  useEffect(() => {
    const newTopGap = clamp(TOP_GAP - topY / SF_Y, 0, 100);
    setTopGap(newTopGap);
  }, [topY]);

  useEffect(() => {
    if (isGenerating) {
      return;
    }

    if (contentCoords?.textLength !== content?.text?.length) {
      return;
    }

    let [caretX, caretY] = contentCoords?.charCoords?.[caretIndex] || [0, 0];

    if (caretIndex === -1) {
      const selectionStart = content?.selectionStyles?.findIndex(
        style => style?.isSelection === true
      );
      [caretX, caretY] = contentCoords?.charCoords?.[selectionStart] || [0, 0];
    }

    caretX = caretX / SF;
    caretY = (caretY - topY) / SF_Y + topGap;

    setCaretCoords({ x: caretX, y: caretY });
  }, [
    JSON.stringify(contentCoords?.charCoords),
    topY,
    JSON.stringify(content?.selectionStyles),
    isGenerating,
  ]);

  const doPopulateWordDoc = async () => {
    setIsLoading(true);
    const { data } = await getWordDoc(wordDocId);
    setWordDoc(data);

    const newContent = getContentFromBlocks(data?.content?.blocks || []);
    newContent.selectionStyles = [null, { isCaret: true }];
    setContent(newContent);
    // setContent(TEST_CONTENT);

    // if empty doc
    if (
      data?.content?.blocks?.length === 1 &&
      data?.content?.blocks?.[0]?.text === ""
    ) {
      setContent(INITIAL_CONTENT);
    }

    setTempBlocks(data?.content?.blocks || []);
    setIsLoading(false);
  };

  const doSaveDoc = async () => {
    setIsDocFocussed(false);
    setIsLoading(true);

    const { blocks } = getBlocksFromContent(content);

    const wordDocPayload = {
      ...wordDoc,
      content: {
        ...(wordDoc?.content || {}),
        blocks,
      },
    };

    const { data } = await patchWordDoc(wordDocId, {}, wordDocPayload);
    setWordDoc(data);
    setIsLoading(false);
  };

  const onPressSend = async prompt => {
    setIsGenerating(true);
    setPromptOfGeneration(prompt);
    let { blocks, cursor } = getBlocksFromContent(content);
    const payloadBlocks = [...blocks];

    if (prompt) {
      const caretIndex = content?.selectionStyles?.findIndex(
        style => style?.isCaret
      );
      const queryId = content?.textStyles?.[caretIndex]?.queryId;
      if (queryId) {
        const queryBlockIndex = payloadBlocks.findIndex(
          b => b?.queryId === queryId
        );
        const queryBlock = payloadBlocks[queryBlockIndex];
        if (queryBlockIndex !== -1) {
          queryBlock.text = prompt;
          cursor = {
            blockIndex: queryBlockIndex,
            letterIndex: 0,
          };
        }
      }

      if (!queryId) {
        payloadBlocks.splice(cursor?.blockIndex + 1, 0, {
          isQuery: true,
          text: prompt,
        });
        cursor.blockIndex += 1;
        cursor.letterIndex = prompt?.length - 1;
      }
    }

    cursor.blockIndex = Math.min(cursor.blockIndex, payloadBlocks.length - 1);
    const payload = {
      blocks: payloadBlocks,
      cursor,
      sources: wordDoc?.content?.sources || [],
      generation_config: {
        do_sample: false,
        max_new_tokens: 100,
      },
    };
    if (!prompt) {
      payload.isTabComplete = true;
    }

    const { error } = await postAndStreamResponse({
      // url: `${BASE_URL}/solutions/ocr/chat-service/api/v1/chatflow/generate-streamed`,
      url: `${BASE_URL}/bz-api/v1/ai/streamed-chat-queries`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }

        const newContent = getContentFromBlocks(data?.blocks, data?.cursor);
        setContent(newContent);
        setTempBlocks(data?.blocks);
      },
    });
    // setContent(prev => {
    //   let newLinesAtEnd = "\n\n\n\n";
    //   if (prev?.text?.endsWith("\n\n\n\n")) {
    //     newLinesAtEnd = "";
    //   }

    //   return {
    //     ...prev,
    //     text: prev?.text + newLinesAtEnd,
    //   };
    // });

    setPromptOfGeneration("");
    setIsGenerating(false);
  };

  const onPaste = e => {
    // const GOOGLE_TYPE =
    //   "application/x-vnd.google-docs-document-slice-clip+wrapped";
    // console.log(
    //   JSON.parse(JSON.parse(e.clipboardData.getData(GOOGLE_TYPE))?.data)
    // );
    const strToInsert = e.clipboardData.getData("text");
    const newContent = insertAtSelection(strToInsert, content);
    setContent(newContent);
  };

  const onCopy = e => {
    let selStart = content?.selectionStyles?.findIndex(
      style => style?.isSelection === true
    );
    let selEnd = content?.selectionStyles?.findIndex(
      style => style?.isSelection === false
    );
    if (selStart === -1 || selEnd === -1) {
      return;
    }
    const strToCopy = content?.text?.slice(selStart, selEnd);
    navigator.clipboard.writeText(strToCopy);
  };

  // ---- KEYBOARD & MOUSE LISTENERS ----

  const onKeyDown = e => {
    if (e?.key === "Tab") {
      e.preventDefault();
      onPressSend();
      return;
    }

    if (["ArrowLeft", "ArrowRight"].includes(e?.key)) {
      const newContent = moveCaret(content, KEY_TO_NUM_CHARS[e.key]);
      setContent(newContent);
      return;
    }

    if (["ArrowUp", "ArrowDown"].includes(e?.key)) {
      const newContent = moveCaretLine(
        content,
        contentCoords,
        KEY_TO_NUM_CHARS[e.key],
        topY
      );
      setContent(newContent);
      return;
    }

    if (e?.key === "Backspace") {
      const newContent = deleteAtSelection(content);
      setContent(newContent);
      return;
    }

    if (e?.key === "Enter") {
      const newContent = insertAtSelection("\n", content);
      setContent(newContent);
      return;
    }

    if (e?.key?.length > 1 || e?.metaKey || e?.ctrlKey || e?.altKey) {
      return;
    }

    const newContent = insertAtSelection(e.key, content);
    setContent(newContent);
  };

  const onMouseDown = e => {
    e.preventDefault();
    document.activeElement.blur();
    setIsDocFocussed(true);
    // setContextMenu(null);
    // if right click
    if (e.button !== 2) {
      setIsMouseDown(true);
    }

    const newStyles = getSelectionStylesFromEvent(
      e,
      content,
      topY,
      contentCoords
    );
    const newContent = cloneDeep(content);
    newContent.selectionStyles = newStyles;
    setContent(newContent);
  };

  const onMouseUp = e => {
    setIsMouseDown(false);
  };

  const onMouseMove = e => {
    e.preventDefault();
    if (!isMouseDown) {
      return;
    }

    const newStyles = getSelectionStylesFromEvent(
      e,
      content,
      topY,
      contentCoords
    );
    const newContent = cloneDeep(content);
    newContent.selectionStyles = newStyles;
    setContent(newContent);
  };

  const addStyleToSelection = (style = {}) => {
    const newTextStyles = content?.textStyles || [];

    range(context.selectionStart, context.selectionEnd).forEach(i => {
      const existingStyle = newTextStyles?.[i] || {};
      newTextStyles[i] = { ...existingStyle, ...style };
    });

    setContent({
      ...content,
      textStyles: newTextStyles,
    });
  };

  const caretIndex = content?.selectionStyles?.findIndex(
    style => style?.isCaret
  );
  let textStyle = caretIndex === -1 ? null : content?.textStyles?.[caretIndex];
  const selectionStart = content?.selectionStyles?.findIndex(
    style => style?.isSelection === true
  );
  const selectionEnd = content?.selectionStyles?.findIndex(
    style => style?.isSelection === false
  );
  if (caretIndex === -1 && selectionStart !== -1) {
    textStyle = content?.textStyles?.[selectionStart];
  }
  // const caretY =
  //   (contentCoords?.charCoords?.[caretIndex]?.[1] - topY) / SF_Y + 60;
  const context = {
    caretIndex,
    char: content?.text?.[caretIndex],
    // caretY,
    textStyle,
    selectionStart,
    selectionEnd,
    caretCoords,
    // selectionStyles: content?.selectionStyles,
    // text: content?.text,
    // ...getBlocksFromContent(content),
  };

  // tool bar state
  const selection = {
    isBold: getSelectionStyle(content, "isBold"),
    isItalic: getSelectionStyle(content, "isItalic"),
    isUnderlined: getSelectionStyle(content, "isUnderlined"),
    bgColor: getSelectionStyle(content, "bgColor"),
    textColor: getSelectionStyle(content, "textColor"),
  };

  const canvasRect = canvasRef?.current?.getBoundingClientRect();

  return (
    <Container isDisabled={isLoading}>
      <ToolBar
        style={{
          // transform: `translateX(${isChatOpen ? 90 : 232}px)`,
          opacity: isJobInProgress ? 0.5 : 1,
          pointerEvents: isJobInProgress ? "none" : "all",
        }}
      >
        <IconButton
          isActive={selection?.isBold}
          onClick={() => addStyleToSelection({ isBold: !selection?.isBold })}
        >
          <FormatBold />
        </IconButton>
        <IconButton
          isActive={selection?.isItalic}
          onClick={() =>
            addStyleToSelection({ isItalic: !selection?.isItalic })
          }
        >
          <FormatItalic />
        </IconButton>
        <IconButton
          isActive={selection?.isUnderlined}
          onClick={() =>
            addStyleToSelection({ isUnderlined: !selection?.isUnderlined })
          }
        >
          <FormatUnderlined />
        </IconButton>
        <ColorPickerTooltip
          selectedColor={selection?.bgColor}
          onNewColor={newColor => {
            addStyleToSelection({
              bgColor: newColor,
            });
          }}
          triggerIcon={
            <IconButton>
              <FormatColorFill />
            </IconButton>
          }
        />
        <ColorPickerTooltip
          highlightChild={1}
          selectedColor={selection?.textColor}
          onNewColor={newColor => {
            addStyleToSelection({
              textColor: newColor,
            });
          }}
          triggerIcon={
            <IconButton>
              <FormatColorText />
            </IconButton>
          }
        />
        <IconButton onClick={doSaveDoc}>
          <Save />
        </IconButton>
        <IconButton
          disabled={!isGenerating}
          onClick={() => {
            abortController.abort();
            setAbortController(new AbortController());
            setIsGenerating(false);
          }}
        >
          <CrossIcon style={{ height: "14px" }} />
        </IconButton>
      </ToolBar>

      <TwoColumns>
        <div style={{ position: "relative", justifySelf: "center" }}>
          <Caret
            key={caretIndex}
            style={{
              visibility:
                caretIndex === -1 || isGenerating || isJobInProgress
                  ? "hidden"
                  : "visible",
              position: "absolute",
              width: 2,
              height: 16,
              transform: `translate(${caretCoords?.x}px, ${caretCoords?.y}px)`,
              backgroundColor: "black",
              marginTop: -13,
              pointerEvents: "none",

              animation: isDocFocussed
                ? "blink 1s step-end infinite 1s"
                : "none",
            }}
          />
          <Canvas
            style={{
              transform: `translateY(${topGap}px)`,
              height: `${VIEW_HEIGHT}px`,
            }}
            width={WIDTH}
            height={HEIGHT}
            ref={canvasRef}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onWheel={e =>
              setTopY(prev => clamp(prev + e.deltaY * SF_Y, 0, 100000))
            }
            onMouseMove={onMouseMove}
          />
          {/* <div style={{ position: "absolute", left: -20 }}>
            <ContextMenu
              style={{
                display: "block",
                top: caretCoords?.y,
              }}
            >
              <WordDocModalTrigger
                modalBlocks={[tempBlocks?.[context?.textStyle?.blockIndex]]}
                context={context}
                textStyles={content?.textStyles}
                onNewStyles={textStyles => {
                  setContent({
                    ...content,
                    textStyles,
                  });
                }}
                onPressSend={onPressSend}
                onPressCancel={() => {
                  abortController.abort();
                  setAbortController(new AbortController());
                }}
                isGenerating={isGenerating}
                onFocus={doSaveDoc}
                initialPrompt={context?.textStyle?.prompt || promptOfGeneration}
                sources={wordDoc?.content?.sources || []}
                content={content}
              />
            </ContextMenu>
          </div> */}
        </div>
        <Slideout isOpen={isChatOpen}>
          <FixedButton onClick={() => setIsChatOpen(prev => !prev)}>
            <ChatIcon />
          </FixedButton>
          <ChatView
            style={{
              gridTemplateRows: "1fr auto",
              height: "calc(100vh - 40px - 50px - 24px - 40px)",
            }}
            workingDocs={[{ id: wordDocId }]}
            abortController={abortController}
            setAbortController={setAbortController}
            onChangeBlocks={newBlocks => {
              const newContent = getContentFromBlocks(newBlocks);
              setContent(newContent);
            }}
            isGenerating={isGenerating}
            setIsGenerating={setIsGenerating}
            onFocus={doSaveDoc}
          />
        </Slideout>
      </TwoColumns>
    </Container>
  );
};

export default WordDocArea;
