import { BoldIcon, ItalicIcon, TableIcon } from "components/IconsNew";
import { cloneDeep } from "lodash";
import styled from "styled-components";

const getSelectionStyle = doc => {
  const selectedStyles = doc?.styles.slice(doc.selStart, doc.selEnd);

  if (!selectedStyles?.length) {
    return { isBold: false };
  }

  return {
    isBold: selectedStyles?.every(style => style?.isBold),
    isItalic: selectedStyles?.every(style => style?.isItalic),
  };
};

const toggleStyle = (doc, styleField) => {
  const selectedStyles = doc?.styles.slice(doc.selStart, doc.selEnd);
  const isActive = selectedStyles?.every(style => style?.[styleField]);

  const newDoc = cloneDeep(doc);
  let i = doc.selStart;
  while (i < doc.selEnd) {
    if (isActive) {
      delete newDoc.styles[i][styleField];
    } else {
      newDoc.styles[i] = {
        ...newDoc.styles[i],
        [styleField]: true,
      };
    }
    i++;
  }

  return newDoc;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    fill: black;
    height: 28px;
    width: 28px;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    :hover {
      background-color: #eaeaea;
    }

    ${props => props.isActive && `background-color: #0191ff;`}
  }
`;

let ACTIVE_STYLE = {
  backgroundColor: "#0191ff22",
};

const DocToolbar = ({ doc = {}, onDocChange = newDoc => {} }) => {
  const selectionStyle = getSelectionStyle(doc);

  const { isBold, isItalic } = selectionStyle;

  const doToggleStyle = styleField => {
    const newDoc = toggleStyle(doc, styleField);
    onDocChange(newDoc);
  };

  return (
    <Container>
      <BoldIcon
        style={isBold ? ACTIVE_STYLE : {}}
        onClick={() => doToggleStyle("isBold")}
      />
      <ItalicIcon
        style={isItalic ? ACTIVE_STYLE : {}}
        onClick={() => doToggleStyle("isItalic")}
      />
      <TableIcon />
    </Container>
  );
};

export default DocToolbar;
