import { getTrainingDataPoints } from "api/backend/trainingEndpoints";
import AreaChartParamsNew from "components/AreaChartParamsNew";
import { Reply } from "components/FieldReferenceModal";
import { TableGridIcon } from "components/IconsNew";
import KeyFiguresParams from "components/KeyFiguresParams";
import { Gap } from "components/Layout";
import LayoutNew from "components/LayoutNew";
import Modal from "components/ui/Modal";
import { last } from "lodash";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { getColorFromString, safeFormat } from "utils/common";

const Container = styled.div`
  padding: 80px 80px;
  position: relative;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
`;

const BoldDiv = styled.div`
  font-weight: 500;
`;

const FlexRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-content: start;
`;

const TableContainer = styled.div`
  overflow: auto;

  table {
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
  }
  table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  table tbody th {
    position: relative;
  }
  table thead th:first-child {
    width: 1%;
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th:first-child {
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const IconAndText = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 0px;
  align-items: center;
`;

const LAST_7_LOSS = [80, 73, 42, 65, 80, 90, 95];
const LAST_7_ACC = [90, 95, 85, 90, 95, 90, 95];
const LAST_7_COUNT = [100, 200, 300, 400, 500, 600, 700];
const LAST_7_CALLS = [250, 800, 900, 1000, 1200, 1500, 1800];

const KEY_FIGURES_RECORDS = [
  {
    fields: {
      "Total Data Points": { value: last(LAST_7_COUNT) },
      "Total Model Versions": { value: 234 },
      "Latest Accuracy (%)": { value: last(LAST_7_ACC) },
    },
  },
];

const getLast7Days = () => {
  let today = new Date();
  let last7Days = [];

  for (let i = 0; i < 7; i++) {
    let date = new Date(today);
    date.setDate(today.getDate() - i);

    const dayData = {
      date: { value: date?.toDateString() },
      accuracy: { value: LAST_7_ACC[i] },
      loss: { value: LAST_7_LOSS[i] },
      count: { value: LAST_7_COUNT[i] },
      "API Calls": { value: LAST_7_CALLS[i] },
    };

    last7Days.push(dayData);
  }

  return last7Days?.reverse();
};

const LAST_7_DAYS = getLast7Days();

const LOSS_PARAMS = {
  xColumnName: "date",
  yColumnNames: ["loss"],
};

const ACCURACY_PARAMS = {
  xColumnName: "date",
  yColumnNames: ["accuracy"],
};

const COUNT_PARAMS = {
  xColumnName: "date",
  yColumnNames: ["count"],
};

const CALLS_PARAMS = {
  xColumnName: "date",
  yColumnNames: ["API Calls"],
};

// TODO
// - data point modal: add tab for JSON view
// - api docs page: add model training endpoints
// - modal: add ingestion time, product name
// - add product filter
// - add arrows to move between data points
const TABLE_COLUMNS = [
  // { name: "id", label: "ID" },
  { name: "createdAt", label: "Creation Date" },
  { name: "product", label: "Product" },
  { name: "formattedOutput", label: "Output" },
];

const ModalContent = styled.div`
  padding: 20px;
  width: 800px;
  height: 600px;
  background-color: #eaeaea;
  border-radius: 20px;
  overflow: auto;
`;

const RepliesContainer = styled.div`
  display: grid;
  gap: 20px;
`;

const AnswerDiv = styled.div`
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  font-weight: 500;
  width: 100%;
  line-height: 1.2;
  min-height: 32px;
`;

const ColoredTag = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  width: max-content;
`;

const InspectDataPointModal = ({
  dataPoint = null,
  handleClose = () => {},
}) => {
  if (!!dataPoint) {
    console.log(dataPoint);
  }

  let outputContent = <AnswerDiv>{dataPoint?.formattedOutput}</AnswerDiv>;
  if (Array.isArray(dataPoint?.formattedOutput)) {
    const columnNames = dataPoint?.formattedOutput?.[0]?.map(r => r?.Key);

    outputContent = (
      <div style={{ maxHeight: 200, overflow: "auto", borderRadius: 8 }}>
        <table>
          <thead>
            <Tr>
              {columnNames?.map((colName, i) => (
                <Th style={{ backgroundColor: "white" }} key={i}>
                  {colName}
                </Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {dataPoint?.formattedOutput?.map((row, i) => {
              return (
                <Tr key={i}>
                  {columnNames?.map((columnName, j) => {
                    const cellValues = row?.find(
                      r => r?.Key === columnName
                    )?.Value;
                    const cellValue = last(cellValues)?.Value;

                    return <Td key={j}>{cellValue}</Td>;
                  })}
                </Tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <Modal open={!!dataPoint} handleClose={handleClose}>
      <ModalContent>
        <BoldDiv>Final Output</BoldDiv>
        <Gap height="8px" />
        {outputContent}
        <Gap height="20px" />
        <BoldDiv>Chain of Thought</BoldDiv>
        <Gap height="10px" />
        <RepliesContainer>
          {dataPoint?.llmPromptReplies?.map((reply, i) => (
            <Reply key={i} reply={reply} index={i} />
          ))}
        </RepliesContainer>
      </ModalContent>
    </Modal>
  );
};

const PRODUCTS = [
  "Data Extraction",
  "Analytics Dashboard",
  "ML Chat",
  "Workspace",
];

const ModelTrainingPage = () => {
  const [dataPoints, setDataPoints] = useState([]);
  const [dataPointToInspect, setDataPointToInspect] = useState(null);

  useEffect(() => {
    doPopulateDataPoints();
  }, []);

  const doPopulateDataPoints = async () => {
    const { data } = await getTrainingDataPoints();
    setDataPoints(data);
  };

  return (
    <LayoutNew>
      <Container>
        <Title>Your model training</Title>
        <Gap height="10px" />
        <a href="/api-docs" target="_blank" rel="noreferrer">
          API Documentation
        </a>
        <Gap />
        <KeyFiguresParams records={KEY_FIGURES_RECORDS} />
        <Gap />
        <FlexRow>
          <AreaChartParamsNew params={COUNT_PARAMS} records={LAST_7_DAYS} />
          <AreaChartParamsNew params={LOSS_PARAMS} records={LAST_7_DAYS} />
          <AreaChartParamsNew params={ACCURACY_PARAMS} records={LAST_7_DAYS} />
          <AreaChartParamsNew params={CALLS_PARAMS} records={LAST_7_DAYS} />
        </FlexRow>
        <Gap />
        <Title>Ingested data</Title>
        <Gap height="10px" />
        <TableContainer>
          <table>
            <thead>
              <tr>
                {TABLE_COLUMNS.map(column => (
                  <Th key={column.name}>{column.label}</Th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataPoints.map((dataPoint, i) => (
                <Tr key={dataPoint?.id}>
                  {TABLE_COLUMNS.map(column => {
                    let value = dataPoint[column.name];
                    if (Array?.isArray(value)) {
                      value = (
                        <IconAndText style={{ color: "#7e7e7e" }}>
                          <TableGridIcon style={{ fill: "#fd9138" }} />
                          Go to table
                        </IconAndText>
                      );
                    }

                    if (column?.name === "product") {
                      const productName = PRODUCTS[i % PRODUCTS.length];
                      value = (
                        <ColoredTag
                          style={{
                            backgroundColor: `${getColorFromString(
                              productName
                            )}55`,
                          }}
                        >
                          {productName}
                        </ColoredTag>
                      );
                    }

                    if (column?.name === "createdAt") {
                      value = safeFormat(value, "dd MMM yyyy HH:mm");
                    }

                    return (
                      <Td
                        onClick={() => setDataPointToInspect(dataPoint)}
                        key={column.name}
                      >
                        {value}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </Container>
      <InspectDataPointModal
        dataPoint={dataPointToInspect}
        handleClose={() => setDataPointToInspect(null)}
      />
    </LayoutNew>
  );
};

export default ModelTrainingPage;
