import { Visibility } from "@material-ui/icons";
import { getFileById, patchFileById } from "api/backend/filesEndpoints";
import FileTagFetcher from "components/FileTagFetcher";
import { PageResult } from "components/FileViewModal";
import InputWithState from "components/InputWithState";
import NavHeader from "components/NavHeader";
import OutputItemTable from "components/OutputItemTable";
import ButtonWord from "components/ui/ButtonWord";
import { FilesIcon, PdfIcon } from "components/ui/Icons";
import useResource from "hooks/useResource";
import { range } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";

export const AREA_TYPE_TO_COLOR = {
  "-": "#aec7e8",
  text: "#0191ff",
  table: "#9650ff",
  infograph: "#ff7f0e",
  image: "#df5d00",
  title: "#009933",
  sidebar: "#00eeff",
  note: "#bcbd22",
  list: "#5c3129",
};

const Container = styled.div`
  background-color: white;
  height: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  background: linear-gradient(180deg, #f3f5f7 0%, #f2f2f2 100%);
`;

const OuterContainer = styled.div`
  padding: 20px;
  background-color: #131718;
  height: 100vh;
`;

const TopBar = styled.div`
  border-bottom: 1px solid #ccc;
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  display: grid;
  grid-template-columns: repeat(3, auto) 1fr auto;
  justify-content: start;
  align-items: center;
  gap: 12px;
  padding: 0px 20px;
`;

const StyledNavHeader = styled(NavHeader)`
  position: relative;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
`;

const PagesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-self: center;
  display: grid;
  justify-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  overflow: hidden;
`;

const SlideOut = styled.div`
  width: ${props => (props.isVisible ? "350px" : "0px")};
  transition: width 0.1s;
  border-left: 1px solid #ccc;
  height: 100%;
  overflow: auto;
`;

const OutputContainer = styled.div`
  white-space: pre-wrap;
  line-height: 1.5;
  overflow: auto;
  height: 100%;

  background-color: white;
  border-bottom-right-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const OutputType = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 10px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OutputContent = styled.div`
  padding: 10px;
  white-space: pre-wrap;
`;

const EditableFileName = styled(InputWithState)`
  font-weight: bold;
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 600;
`;

const FixedButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px;
  z-index: 1;
  svg {
    height: 18px;
    padding: 0;
    opacity: 0.6;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
  }
`;

const ZoomBtn = styled(ButtonWord)`
  padding: 0;
  width: 14px;
  height: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFilesIcon = styled(FilesIcon)`
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
  :active {
    fill: ${props => props.theme.color.primary};
  }
`;

const OutputItem = ({ outputItem }) => {
  let outputContent = <OutputContent>{outputItem?.content}</OutputContent>;
  if (outputItem?.areaType === "table") {
    const { tableColumns, automaticLineItemNameMap, tablePreview, tableName } =
      outputItem;

    outputContent = (
      <OutputItemTable
        tableName={tableName}
        tableColumns={tableColumns}
        tablePreview={tablePreview}
        automaticLineItemNameMap={automaticLineItemNameMap}
      />
    );
  }

  let outputAction = (
    <StyledFilesIcon
      onClick={() => navigator.clipboard.writeText(outputItem?.content)}
    />
  );
  if (outputItem?.areaType === "table") {
    outputAction = null;
  }

  return (
    <OutputContainer>
      <OutputType
        style={{
          backgroundColor: `${AREA_TYPE_TO_COLOR[outputItem?.areaType]}22`,
        }}
      >
        {outputItem?.areaType?.toUpperCase()}
        {outputAction}
      </OutputType>
      {outputContent}
    </OutputContainer>
  );
};

const OcrPage = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [scrollTop, setScrollTop] = useState(0);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [selectedOutputItem, setSelectedOutputItem] = useState(null);

  const [file, setFile] = useState(null);

  const isInspecting = searchParams.get("isInspecting") === "true";

  useEffect(() => {
    doPopulateFile();
  }, [fileId]);

  const doPopulateFile = async () => {
    const { data } = await getFileById(fileId);
    setFile(data);
  };

  const doPatchFile = async body => {
    const { data } = await patchFileById(fileId, body);
    setFile(data);
  };

  const toggleBoxesVisible = () => {
    if (isInspecting) {
      searchParams.delete("isInspecting");
    } else {
      searchParams.set("isInspecting", true);
    }
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <OuterContainer>
      <Container>
        <TopBar>
          <Link to="/">
            <PdfIcon
              height="22px"
              style={{
                fill: "#ed1c24",
              }}
            />
          </Link>
          <EditableFileName
            initialValue={file?.fileName}
            onApplyValue={newFileName => doPatchFile({ fileName: newFileName })}
          />
          <FileTagFetcher fileId={fileId} />
          <div />
          <StyledNavHeader />
        </TopBar>
        <TwoColumns>
          <FixedButtonsContainer>
            <ZoomBtn
              onClick={() => setZoomFactor(prev => Math.max(prev - 0.5, 1))}
            >
              -
            </ZoomBtn>
            <ZoomBtn
              onClick={() => setZoomFactor(prev => Math.min(prev + 0.5, 10))}
            >
              +
            </ZoomBtn>
            <Visibility
              onClick={toggleBoxesVisible}
              style={{
                fill: isInspecting ? "#0191ff" : "#000",
              }}
            />
          </FixedButtonsContainer>
          <PagesContainer onScroll={e => setScrollTop(e?.target?.scrollTop)}>
            {range(0, file?.numberOfPages).map(pageNumber => (
              <PageResult
                zoomFactor={zoomFactor}
                isAlwaysVisible={pageNumber === 0}
                fileId={fileId}
                key={`${fileId}-${pageNumber}`}
                pageNumber={pageNumber}
                scrollTop={scrollTop}
                areBoxesVisible
                onClickOutputItem={outputItem =>
                  setSelectedOutputItem(outputItem)
                }
              />
            ))}
          </PagesContainer>
          <SlideOut isVisible={!!selectedOutputItem}>
            <OutputItem outputItem={selectedOutputItem} />
          </SlideOut>
        </TwoColumns>
      </Container>
    </OuterContainer>
  );
};

export default OcrPage;
