import { CircularProgress, Table } from "@material-ui/core";
import { DocSearchIcon, TableGridIcon } from "components/IconsNew";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TableContainer = styled.div`
  overflow: auto;

  table {
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
  }
  table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  table tbody th {
    position: relative;
  }
  table thead th:first-child {
    width: 1%;
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th:first-child {
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const NoRecordsDiv = styled.div`
  height: 100%;
  border: 1px solid ${props => props.theme.color.closer1};
  border-left: none;
  border-right: none;
  display: flex;
  text-align: center;
  align-items: center;
  color: ${props => props.theme.color.closer1};
  justify-content: center;
  font-weight: 600;
  position: sticky;
  left: 0;
  gap: 20px;
  svg {
    color: ${props => props.theme.color.primary}55;
  }
`;

const MaxWidthDiv = styled.div`
  white-space: nowrap;
  max-width: 200px;
`;

const EllpsisDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconAndText = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 0px;
  align-items: center;
`;

const HasMoreBtn = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 8px 0;
  padding-right: 0px;
  gap: 8px;
  align-items: start;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  cursor: pointer;
  opacity: 0.6;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8d8d8d;
  font-weight: 500;
  text-decoration: none;
  margin-left: 0px;
  margin-bottom: 10px;

  :hover {
    opacity: 1;
  }
  svg {
    color: #0191ff;
  }
`;

const PipelineRecordsTable = ({
  columns = [],
  pipelineConfig = {},
  sigmaRecords = [],
  initialRecordIds = [],
  onClickLoadMore = () => {},
  isShowMoreHidden = false,
  isLoading = false,
}) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <TableContainer>
        <NoRecordsDiv>
          Loading... <CircularProgress thickness={10} size={18} />
        </NoRecordsDiv>
      </TableContainer>
    );
  }

  if (!sigmaRecords?.length) {
    return (
      <TableContainer>
        <NoRecordsDiv>No records</NoRecordsDiv>
      </TableContainer>
    );
  }

  return (
    <TableContainer>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns?.map((column, i) => {
              const columnName = column?.name?.split("_")?.[1] || column?.name;
              return <Th key={column.name}>{columnName}</Th>;
            })}
          </tr>
        </thead>
        <tbody>
          {sigmaRecords.map(record => (
            <Tr key={`record-${record?.id}`}>
              {columns?.map((column, i) => {
                const onClick = () =>
                  navigate(`/apps/${pipelineConfig?.id}/records/${record?.id}`);

                const { value, rawValue } = record?.fields?.[column.name] || {};
                let cellValue = value ?? rawValue;
                if (!!cellValue && typeof cellValue === "object") {
                  cellValue = JSON.stringify(cellValue);
                }

                if (i === 0) {
                  let highlightStyle = {};
                  if (!initialRecordIds?.includes(record?.id)) {
                    highlightStyle = { borderLeft: `4px solid #0191ff` };
                  }

                  return (
                    <Th
                      style={{ maxWidth: 400, ...highlightStyle }}
                      onClick={onClick}
                      key={column.name}
                    >
                      <IconAndText>
                        <DocSearchIcon />
                        <MaxWidthDiv>
                          <EllpsisDiv>{cellValue}</EllpsisDiv>
                        </MaxWidthDiv>
                      </IconAndText>
                    </Th>
                  );
                }

                if (column?.type === "TABLE") {
                  return (
                    <Td key={column.name} onClick={onClick}>
                      <IconAndText style={{ color: "#7e7e7e" }}>
                        <TableGridIcon style={{ fill: "#fd9138" }} />
                        Go to table
                      </IconAndText>
                    </Td>
                  );
                }

                return (
                  <Td key={column.name} onClick={onClick}>
                    {cellValue}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </tbody>
      </table>
      {!isShowMoreHidden && (
        <HasMoreBtn onClick={onClickLoadMore}>Load more</HasMoreBtn>
      )}
    </TableContainer>
  );
};

export default PipelineRecordsTable;
