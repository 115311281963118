import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { cloneDeep, set, uniq } from "lodash";

import { PlusIcon, TickIcon, TrashIcon, WordIcon } from "components/ui/Icons";
import { sleep, uuidv4 } from "utils/common";
import {
  getPipelineConfig,
  postDirectories,
  postMoveItem,
  postPipelineConfigs,
  postPipelineConfigsUploadCsv,
  postSigmasV2,
  putPipelineConfig,
} from "api/backend/fileSystemEndpoints";
import ButtonWord from "components/ui/ButtonWord";
import { Gap } from "components/Layout";
import {
  getWordDoc,
  postWordDoc,
  postWordDocsUploadSolutions,
} from "api/backend/wordDocsEndpoints";
import LayoutNew from "components/LayoutNew";
import LayoutApp, {
  getAppBasePathSource,
  getAppBasePathWorking,
} from "components/LayoutApp";
import { postSequencesCreate } from "api/backend/projectServiceEndpoints";
import {
  deleteAppTemplate,
  getAppTemplates,
  postAppTemplates,
} from "api/backend/appTemplatesEndpoints";
import SaveTemplateModal from "components/SaveTemplateModal";
import { DocSearchIcon, FunnelIcon } from "components/IconsNew";
import { COLOR1, COLOR2 } from "pages/login-v2";
import {
  INITIAL_H,
  INITIAL_W,
  getAvailableSpace,
  getInitialLayoutFromColumns,
} from "pages/apps/:pipelineConfigId";

const ModalContent = styled.div`
  position: relative;
  padding: 32px;
  padding-top: 60px;
  height: 100%;
  display: grid;
  align-content: start;
  gap: 15px;
  background: linear-gradient(180deg, #f3f5f7 0%, #f3f5f7 100%);
  ${props =>
    props.isDisabled &&
    "pointer-events: none; opacity: 0.5;"}/* background-color: #f3f5f7; */
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const Td = styled.td`
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px 20px;

  font-weight: 600;
  z-index: 1;
`;

const Table = styled.table`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 12px;
  border-collapse: separate;
  background-color: white;
`;

const TableContainer = styled.div`
  min-width: 800px;
  height: 300px;
  overflow: auto;
`;

const GreyedOutTr = styled.tr`
  opacity: 0.5;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledBoldInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-family: "Montserrat";
  width: 120px;
  padding: 0;
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  resize: none;
  height: 100px;
  :disabled {
    opacity: 0.5;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props?.disabled && "pointer-events: none; opacity: 0.5;"}
`;

const BoldDiv = styled.span`
  font-weight: 600;
`;

// const StyledSelect = styled.select`
//   border: 1px solid ${props => props.theme.color.closer1};
//   border-radius: 0;
//   background-color: ${props => props.theme.color.furthest};
//   outline: none;
//   font-family: "Montserrat", sans-serif;

//   :hover {
//     opacity: 0.6;
//   }

//   :focus {
//     border: 1px solid ${props => props.theme.color.primary};
//   }
// `;

const BottomButtons = styled.div`
  position: absolute;
  bottom: 0px;
  background-color: white;
  width: 100%;
  padding: 10px 32px;
  padding-bottom: 20px;
  display: grid;
  justify-content: end;
  border-top: 1px solid #eaeaea;

  display: grid;
  grid-template-columns: 1fr auto;
`;

const NextButton = styled(ButtonWord)``;

const Message = styled.div``;

const APP_TEMPLATES = [
  {
    id: "0",
    name: "Deals App",
    type: "case",
    groupNames: ["Tranche Details", "Timings"],
    columns: [
      {
        id: uuidv4(),
        name: "Name",
        description: "Overall record name",
        groupName: "",
        type: "TEXT",
      },
      {
        id: uuidv4(),
        name: "Ticker",
        description: "",
        groupName: "Tranche Details",
        type: "TEXT",
      },
      {
        id: uuidv4(),
        name: "Currency",
        description: "Amount of the deal",
        groupName: "Tranche Details",
        type: "TEXT",
      },
      {
        id: uuidv4(),
        name: "Deal Date",
        description: "",
        groupName: "Timings",
        type: "TEXT",
      },
    ],
  },
  {
    id: "blank",
    name: "",
    type: "case",
    columns: [],
  },
];

const STATES = ["templates", "columns"];
const DISABLED_STATES = [];
const STATE_LABELS = ["Choose template", "More details"];
const TABLE_COLUMNS = [
  { label: "Name", name: "name" },
  { label: "Action type", name: "actionType" },
  { label: "Description", name: "description" },
  { label: "Data Type", name: "type" },
];

const FILE_TABLE_COLUMNS = [
  { label: "Name", name: "name" },
  { label: "Template file" },
];

const SwitchLinks = styled.div`
  background-color: #eaeaea;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 6px;
  border-radius: 14px;
  width: 200px;
`;

const SwitchLink = styled.span`
  color: white;
  background-color: ${props => (props.isActive ? "#c2c2c2" : "#eaeaea")};
  color: #434343;
  text-decoration: none;
  padding: 8px;
  border-radius: 10px;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: #000000;
  }
`;

const GroupName = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  padding: 8px;
  font-weight: 600;
  border-radius: 10px;
  :hover {
    background-color: #eaeaea;
  }
`;

const TableGroupName = styled.div`
  padding: 8px 0;
  font-weight: 600;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StepsNav = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
`;

const StepNavItem = styled.div`
  padding: 0px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 0px;
  :hover {
    background-color: #eaeaea;
  }

  ${props => props?.isActive && "color: #0191ff;"}
  ${props => props?.disabled && "pointer-events: none; opacity: 0.5;"}
`;

const TemplateTrashIcon = styled(TrashIcon)`
  height: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  :hover {
    opacity: 1 !important;
  }
`;

const TemplateCard = styled.div`
  width: 245px;
  height: 136px;
  background-color: white;
  padding: 12px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
  position: relative;
  :hover {
    ${TemplateTrashIcon} {
      opacity: 0.5;
    }

    box-shadow: 0px 1px 7px 1px #00000040;
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);
    svg {
      fill: white;
    }
    color: white;
    * {
      color: white;
    }
    td,
    th {
      background-color: transparent;
    }
  }
`;

const TemplateDescription = styled.div`
  font-weight: 400;
  padding-top: 10px;
  color: #afb6b9;
`;

const BLUE = "#c0e3ffff";
const GREEN = "#b8f6c5ff";
const PURPLE = "#e2cfffff";
const ORANGE = "#ffe8d5";

const SPECIAL_GROUPS = ["", "File Templates", "Table Columns"];

const Tr = styled.tr`
  ${props =>
    props.isIndented &&
    `
  td:first-child {
    padding-left: 40px;
  }
  td {
    background-color: ${ORANGE};
  }
  `}
`;

const IconAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    fill: black;
    height: 14px;
  }
`;

const Label = styled.label`
  padding: 0 12px;
  gap: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: #141718;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  :hover {
    color: #eaeaea;
  }

  ${props =>
    props?.disabled &&
    `pointer-events: none;
    background-color: #e0e0e0;
  `}
`;

const FIRST_STEP_ID = uuidv4();
const INITIAL_SEQUENCE_BODY = {
  meta: {
    layout: {
      [FIRST_STEP_ID]: {
        h: 4,
        w: 10,
        x: 8,
        y: 7,
      },
      step0: {
        h: 4,
        w: 10,
        x: 8,
        y: 1,
      },
    },
  },
  name: "Auto email example",
  sharedWith: [
    {
      accessLevel: "Owner",
      userGroupId: "test-user",
    },
  ],
  steps: [
    {
      action: {
        condition: "",
        offsetMS: 0,
        type: "",
      },
      function: {
        prompt: "",
        recipients: null,
        savePath: "",
        subject: "",
        template: "",
        type: "",
      },
      id: "step0",
      name: "Sequence trigger",
      next: [FIRST_STEP_ID],
      output: null,
      type: "manually-triggered",
    },
    {
      action: {
        condition: "",
        offsetMS: 0,
        type: "",
      },
      function: {
        prompt: "",
        recipients: ["Dets_Email"],
        savePath: "",
        subject: "Greetings",
        template: "Welcome to the app",
        type: "",
      },
      id: FIRST_STEP_ID,
      name: "Send email",
      next: null,
      output: null,
      type: "email",
    },
  ],
};

export const doCreateInitialResources = async pipelineConfig => {
  // initialise app folders
  await postDirectories({
    path: getAppBasePathSource(pipelineConfig?.id),
  });
  await postDirectories({
    path: getAppBasePathWorking(pipelineConfig?.id),
  });

  // initialise with blank record
  const recordNameColumn = pipelineConfig?.sourceTables?.[0]?.columns?.find(
    column => !column?.groupName
  );

  // initalise word docs
  const wordDocColumns = pipelineConfig?.sourceTables?.[0]?.columns?.filter(
    column => column?.groupName === "File Templates"
  );
  let wordDocFields = {};
  const wordDocPromises = wordDocColumns?.map(async column => {
    const { data: templateWordDoc } = await getWordDoc(column?.description);

    // move template word doc
    await postMoveItem({
      id: templateWordDoc?.id,
      newDirectoryPath: getAppBasePathWorking(pipelineConfig?.id),
      itemType: "WORD_DOC",
    });

    const { data: wordDoc } = await postWordDoc(
      {},
      {
        fileName: column?.name,
        content: templateWordDoc?.content,
      }
    );
    wordDocFields[column.name] = {
      value: wordDoc.id,
    };
  });
  await Promise.all(wordDocPromises);

  await postSigmasV2(
    {},
    {
      pipelineId: pipelineConfig?.id,
      tableName: pipelineConfig?.sourceTables?.[0]?.id,
      fields: {
        [recordNameColumn?.name]: {
          value: "New Record",
        },
        ...wordDocFields,
      },
    }
  );

  // initialise plot
  const componentId = uuidv4();
  const aggregationComponents = [
    {
      id: componentId,
      label: "Record Count",
      nlc: "count records",
      type: "Table",
      data: {
        execResult: {
          columnTypes: {
            count: "UNKNOWN",
          },
          message: "",
          records: [
            {
              createdAt: "0001-01-01T00:00:00Z",
              createdBy: "",
              fields: {
                count: {
                  reference: null,
                  value: 1,
                },
              },
              id: 0,
              pipelineId: "",
              tableName: "",
              updatedAt: "0001-01-01T00:00:00Z",
            },
          ],
          sql: "SELECT COUNT(*) FROM source0;",
        },
        plots: [
          {
            type: "Table",
            xColumnName: "",
            yColumnNames: [],
          },
        ],
      },
    },
  ];
  const dashboardLayout = {
    [componentId]: {
      h: 14,
      w: 24,
      x: 2,
      y: 2,
    },
  };
  const newPipelineConfig = cloneDeep(pipelineConfig);
  newPipelineConfig.aggregationComponents = aggregationComponents;
  // newPipelineConfig.meta.dashboardLayout = dashboardLayout;
  set(newPipelineConfig, "meta.dashboardLayout", dashboardLayout);

  await putPipelineConfig(pipelineConfig?.id, {}, newPipelineConfig);

  // initial sequence
  const sequencePayload = {
    pipelineId: pipelineConfig?.id,
    ...INITIAL_SEQUENCE_BODY,
  };

  const emailColumnName = pipelineConfig?.sourceTables?.[0]?.columns?.find(
    column => column?.type === "EMAIL"
  )?.name;
  sequencePayload.steps[1].function.recipients = [emailColumnName];

  await postSequencesCreate({}, sequencePayload);
};

export const SwitchLinksBar = ({ type = "", setType = () => {} }) => (
  <SwitchLinks>
    {/* <SwitchLink onClick={() => setType("db")} isActive={type === "db"}>
      DB
    </SwitchLink> */}
    <SwitchLink onClick={() => setType("case")} isActive={type === "case"}>
      Case
    </SwitchLink>
    <SwitchLink onClick={() => setType("file")} isActive={type === "file"}>
      File
    </SwitchLink>
  </SwitchLinks>
);

const TableRow = ({
  isEditingRestricted = false,
  column = {},
  columns = [],
  setColumns = () => {},
  isIndented = false,
  isFileRow = false,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const onChangeField = (e, fieldName) => {
    const newColumns = cloneDeep(columns);
    const columnToEdit = newColumns.find(col => col.id === column.id);
    columnToEdit[fieldName] = e.target.value;
    setColumns(newColumns);
  };

  const onChangeWordDocTemplate = async (e, columnId) => {
    setIsUploading(true);
    const formDataBody = new FormData();
    for (let i = 0; i < e?.target?.files?.length; i++) {
      formDataBody.append("wordDocFile", e?.target?.files?.[i]);
    }
    const { data } = await postWordDocsUploadSolutions({}, formDataBody);

    const newColumns = cloneDeep(columns);
    const columnToEdit = newColumns.find(col => col.id === columnId);
    columnToEdit["description"] = data?.id;
    setColumns(newColumns);
    setIsUploading(false);
  };

  if (isFileRow) {
    return (
      <Tr isIndented={isIndented}>
        <Td>
          <StyledInput
            disabled={isUploading || isEditingRestricted}
            placeholder="File Name"
            value={
              isEditingRestricted
                ? column["name"]?.split("_")?.[1]
                : column["name"]
            }
            onChange={e => onChangeField(e, "name")}
          />
        </Td>
        <Td>
          {!isEditingRestricted && (
            <input
              disabled={isUploading || isEditingRestricted}
              accept=".docx"
              type="file"
              onChange={e => onChangeWordDocTemplate(e, column?.id)}
            />
          )}
          {isEditingRestricted && column?.description && (
            <a
              href={`/word-docs/${column?.description}`}
              target="_blank"
              style={{ display: "flex", alignItems: "center", gap: 4 }}
            >
              <WordIcon /> Template Content
            </a>
          )}
          {!isEditingRestricted && column?.description && <TickIcon />}
        </Td>
        <Td>
          <StyledTrashIcon
            disabled={isEditingRestricted}
            onClick={() =>
              setColumns(columns.filter(col => col.id !== column.id))
            }
          />
        </Td>
      </Tr>
    );
  }

  return (
    <>
      <Tr isIndented={isIndented}>
        {TABLE_COLUMNS.map(tableColumn => {
          const onChange = e => {
            const newColumns = cloneDeep(columns);
            const columnToEdit = newColumns.find(col => col.id === column.id);
            columnToEdit[tableColumn?.name] = e.target.value;
            setColumns(newColumns);
          };

          if (tableColumn?.name === "actionType") {
            let style = { backgroundColor: BLUE };
            if (column[tableColumn?.name] === "manual") {
              style = { backgroundColor: GREEN };
            }
            if (column[tableColumn?.name] === "reasoning") {
              style = { backgroundColor: PURPLE };
            }

            return (
              <Td key={tableColumn?.name}>
                <StyledSelect
                  disabled={isEditingRestricted}
                  style={style}
                  value={column[tableColumn?.name]}
                  onChange={onChange}
                >
                  <option value="extraction">Extraction</option>
                  <option value="manual">Manual</option>
                  <option value="reasoning">Reasoning</option>
                </StyledSelect>
              </Td>
            );
          }

          if (tableColumn?.name === "description") {
            return (
              <Td key={tableColumn?.name}>
                <StyledInput
                  value={column[tableColumn?.name]}
                  onChange={onChange}
                />
              </Td>
            );
          }

          if (tableColumn?.name === "type") {
            let style = { backgroundColor: BLUE };
            if (column[tableColumn?.name] === "NUMBER") {
              style = { backgroundColor: GREEN };
            }
            if (column[tableColumn?.name] === "DATETIME") {
              style = { backgroundColor: PURPLE };
            }
            if (column[tableColumn?.name] === "TABLE") {
              style = { backgroundColor: ORANGE };
            }

            // if (column?.type !== "CATEGORICAL") {
            //   style.gridColumn = "1 / -1";
            // }

            // allow changing between text and email and categorical
            if (
              isEditingRestricted &&
              ["TEXT", "EMAIL", "CATEGORICAL"].includes(
                column[tableColumn?.name]
              )
            ) {
              return (
                <Td key={tableColumn?.name}>
                  <StyledSelect
                    style={style}
                    value={column[tableColumn?.name]}
                    onChange={onChange}
                  >
                    <option value="TEXT">Text</option>
                    <option value="EMAIL">Email</option>
                    <option value="CATEGORICAL">Categorical</option>
                  </StyledSelect>
                </Td>
              );
            }

            return (
              <Td key={tableColumn?.name}>
                <StyledSelect
                  disabled={isEditingRestricted}
                  style={style}
                  value={column[tableColumn?.name]}
                  onChange={onChange}
                >
                  <option value="TEXT">Text</option>
                  <option value="NUMBER">Number</option>
                  <option value="DATETIME">Datetime</option>
                  <option value="TABLE">Table</option>
                  <option value="CATEGORICAL">Categorical</option>
                  <option value="EMAIL">Email</option>
                  <option value="CURRENCY">Currency</option>
                  <option value="PERCENTAGE">Percentage</option>
                  <option value="LOCATION">Location</option>
                </StyledSelect>
              </Td>
            );
          }

          let columnValue = column?.[tableColumn?.name];
          if (columnValue?.includes("_")) {
            columnValue = columnValue?.split("_")?.[1];
          }
          return (
            <Td key={tableColumn?.name}>
              <StyledInput
                disabled={isEditingRestricted}
                placeholder={tableColumn?.name}
                value={columnValue}
                onChange={onChange}
              />
            </Td>
          );
        })}

        <Td>
          <StyledTrashIcon
            disabled={isEditingRestricted}
            onClick={() =>
              setColumns(columns.filter(col => col.id !== column.id))
            }
          />
        </Td>
      </Tr>
      {/* {column?.type === "CATEGORICAL" && <input placeholder="Add categories" />} */}
    </>
  );
};

// TEXT, NUMBER, DATETIME, PERCENTAGE, CURRENCY, TABLE
const AppSettingsPage = ({ trigger = null }) => {
  const navigate = useNavigate();
  const { pipelineConfigId } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [modalState, setModalState] = useState(STATES[0]);
  const [existingPipelineConfig, setExistingPipelineConfig] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [templates, setTemplates] = useState([]);
  const [description, setDescription] = useState("");

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [columns, setColumns] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [groupNames, setGroupNames] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isUploadingCsv, setIsUploadingCsv] = useState(false);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  useEffect(() => {
    if (pipelineConfigId === "new") {
      return;
    }
    doPopulateExistingPipelineConfig();
    setModalState("columns");
  }, [pipelineConfigId]);

  useEffect(() => {
    if (pipelineConfigId !== "new" || columns?.length > 0) {
      return;
    }

    const initialColumns = groupNames?.map(groupName => ({
      id: uuidv4(),
      name: "",
      description: "",
      groupName,
      type: "TEXT",
    }));
    let fileColumns = [];
    if (
      type === "file" &&
      !columns?.find(column => column.groupName === "File Templates")
    ) {
      fileColumns = [
        {
          id: uuidv4(),
          name: "",
          description: "",
          groupName: "File Templates",
          type: "TEXT",
        },
      ];
    }
    setColumns([
      {
        id: uuidv4(),
        name: "Name",
        description: "Overall record name",
        actionType: "extraction",
        groupName: "",
        type: "TEXT",
      },
      ...fileColumns,
      ...initialColumns,
    ]);
  }, [JSON.stringify(groupNames, columns)]);

  const doPopulateTemplates = async () => {
    const { data } = await getAppTemplates();
    setTemplates(data);
  };

  const onChangeCsvFile = async e => {
    setIsUploadingCsv(true);

    const files = e?.target?.files || [];
    const formDataBody = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formDataBody.append("files", files[i]);
    }

    const { data, error } = await postPipelineConfigsUploadCsv(
      { pipelineName: "Untitled App (csv)" },
      formDataBody
    );

    window.location = '/?sidebar=%7B"type"%3A"pipelines"%7D';
  };

  const onClickDone = async () => {
    if (pipelineConfigId === "new") {
      doCreatePipelineConfig();
      return;
    }
    await doUpdatePipelineConfig();
    navigate(`/apps/${pipelineConfigId}/records`);
  };

  const doUpdatePipelineConfig = async () => {
    setIsLoading(true);
    const newPipeline = cloneDeep(existingPipelineConfig);

    const prefixedColumns = columns.map(column => {
      let updatedColumn = { ...column };

      if (updatedColumn?.type === "CATEGORICAL") {
        updatedColumn.categories = updatedColumn?.description?.split(",");
      }

      if (column?.name?.startsWith(column?.groupName)) {
        return updatedColumn;
      }
      updatedColumn.name = `${column?.groupName}_${column?.name}`;

      return updatedColumn;
    });

    // layout
    let newLayout = cloneDeep(newPipeline?.meta?.layout || {});

    Object.entries(newLayout).forEach(([key, value]) => {
      if (!groupNames.includes(key)) {
        delete newLayout[key];
      }
    });

    groupNames.forEach(groupName => {
      if (!newLayout[groupName]) {
        newLayout[groupName] = { x: 1, y: 1, w: INITIAL_W, h: INITIAL_H };
      }
    });

    newPipeline.name = name;
    if (!newPipeline.meta) {
      newPipeline.meta = {};
    }
    newPipeline.meta.description = description;
    newPipeline.meta.layout = newLayout;
    newPipeline.sourceTables[0].columns = prefixedColumns;

    await putPipelineConfig(pipelineConfigId, {}, newPipeline);
    setIsLoading(false);
  };

  const doPopulateExistingPipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setExistingPipelineConfig(data);
    const columnsWithIds = data?.sourceTables?.[0]?.columns?.map(col => {
      if (col?.type === "TABLE") {
        return {
          id: uuidv4(),
          ...col,
          tableColumns: col?.tableColumns?.map(subCol => ({
            id: uuidv4(),
            ...subCol,
          })),
        };
      }

      return {
        id: uuidv4(),
        ...col,
      };
    });
    setName(data?.name);
    setDescription(data?.meta?.description);
    setColumns(columnsWithIds);

    const existingGroupNames = data?.sourceTables?.[0]?.columns?.map(
      col => col?.groupName
    );
    setGroupNames(uniq(existingGroupNames));
  };

  const doCreatePipelineConfig = async () => {
    setIsLoading(true);

    const prefixedColumns = columns
      .filter(col => {
        if (col?.groupName !== "File Templates") {
          return true;
        }

        return !!col?.description;
      })
      ?.map(column => {
        let updatedColumn = {
          ...column,
          actionType: column?.actionType || "extraction",
        };

        if (updatedColumn?.type === "CATEGORICAL") {
          updatedColumn.categories = updatedColumn?.description?.split(",");
        }

        if (column?.name?.startsWith(column?.groupName)) {
          return updatedColumn;
        }
        updatedColumn.name = `${column?.groupName}_${column?.name}`;

        return updatedColumn;
      });

    const pipelineConfig = {
      name: name?.trim(),
      meta: {
        type,
        description,
        layout: getInitialLayoutFromColumns({
          keys: uniq(prefixedColumns?.map(col => col?.groupName)),
          columns: prefixedColumns,
        }),
      },
      sourceTables: [
        {
          id: "source0",
          columns: prefixedColumns,
        },
      ],
    };

    const { data: createdConfig, error } = await postPipelineConfigs(
      {},
      pipelineConfig
    );
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    await doCreateInitialResources(createdConfig);

    navigate(`/apps/${createdConfig?.id}/records/1`);
    setIsLoading(false);
  };

  const onClickTemplate = async template => {
    setName(template?.appName || "");

    const templateContent = JSON.parse(template?.template || "{}");

    setType("case");

    const templateGroupNames =
      uniq(templateContent?.columns?.map(col => col?.groupName)) || [];
    setGroupNames(
      ["File Templates", ...templateGroupNames] || ["File Templates"]
    );
    await sleep(100);

    setColumns(
      templateContent?.columns || [
        {
          id: uuidv4(),
          groupName: "",
          name: "Name",
          type: "TEXT",
          actionType: "extraction",
        },
        {
          id: uuidv4(),
          groupName: "File Templates",
          name: "",
          type: "TEXT",
          actionType: "extraction",
        },
      ]
    );
    setModalState("columns");
  };

  const removeGroup = groupName => {
    setGroupNames(groupNames.filter(name => name !== groupName));
    setColumns(columns.filter(col => col.groupName !== groupName));
  };

  const addNewGroup = () => {
    setGroupNames([...groupNames, newGroupName]);
    const emptyColumn = {
      id: uuidv4(),
      name: "",
      description: "",
      actionType: "extraction",
      groupName: newGroupName,
      type: "TEXT",
    };
    setColumns([...columns, emptyColumn]);
    setNewGroupName("");
  };

  let modalContent = null;
  const stepsNav = (
    <StepsNav>
      {STATES?.map((state, i) => (
        <>
          <StepNavItem
            disabled={DISABLED_STATES?.includes(state)}
            isActive={modalState === state}
            onClick={() => setModalState(state)}
          >
            {STATE_LABELS?.[i]}
          </StepNavItem>
          {i < STATES?.length - 1 && <div>&nbsp;&nbsp;→&nbsp;&nbsp;</div>}
        </>
      ))}
    </StepsNav>
  );

  if (modalState === "templates") {
    let publicTemplates = templates?.filter(
      template => template?.createdBy === "PUBLIC"
    );
    publicTemplates = [{ id: "blank" }, ...publicTemplates];
    const privateTemplates = templates?.filter(
      template => template?.createdBy !== "PUBLIC"
    );

    const renderTemplateCard = template => {
      const templateContent = JSON.parse(template?.template || "{}");

      return (
        <TemplateCard
          key={template.id}
          onClick={() => onClickTemplate(template)}
        >
          <IconAndName>
            <DocSearchIcon />
            {template.appName || "Blank"}
          </IconAndName>
          <TemplateDescription>
            {template?.id === "blank"
              ? "A clean slate"
              : templateContent?.description}
          </TemplateDescription>
          <TemplateTrashIcon
            style={{ display: template?.id === "blank" ? "none" : "block" }}
            onClick={async e => {
              e.stopPropagation();
              e.preventDefault();
              await deleteAppTemplate(template?.id, {
                isPublic: template?.createdBy === "PUBLIC",
              });
              setTemplates(templates.filter(t => t.id !== template?.id));
            }}
          />
        </TemplateCard>
      );
    };

    modalContent = (
      <ModalContent isDisabled={isLoading}>
        {stepsNav}
        <Title>Choose template</Title>
        <Gap height="10px" />
        <div
          style={{
            display: "grid",
            justifyContent: "start",
            gap: 10,
            gridTemplateColumns: "auto auto auto auto",
          }}
        >
          <BoldDiv style={{ gridColumn: "1 / -1" }}>Basic Templates</BoldDiv>
          {publicTemplates.map(renderTemplateCard)}
          <BoldDiv style={{ marginTop: 40, gridColumn: "1 / -1" }}>
            Your Templates
          </BoldDiv>
          {!privateTemplates?.length && (
            <div style={{ gridColumn: "1 / -1", opacity: 0.5, height: 100 }}>
              No templates
            </div>
          )}
          {privateTemplates.map(renderTemplateCard)}
          <BoldDiv style={{ marginTop: 40, gridColumn: "1 / -1" }}>
            From prompt
          </BoldDiv>
          <div style={{ display: "flex", gap: 10, gridColumn: "1 / -1" }}>
            <StyledInput
              style={{ width: 600, backgroundColor: "white" }}
              placeholder="An app to keep track of my customers"
            />
            <ButtonWord isPrimary disabled>
              Generate
            </ButtonWord>
          </div>
          <BoldDiv style={{ marginTop: 40, gridColumn: "1 / -1" }}>
            From CSV file
          </BoldDiv>
          <div style={{ display: "flex", gap: 10, gridColumn: "1 / -1" }}>
            <Label disabled={isUploadingCsv}>
              Upload
              <input
                type="file"
                style={{ display: "none" }}
                onChange={onChangeCsvFile}
              />
            </Label>
            {/* <StyledInput
              style={{ width: 600, backgroundColor: "white" }}
              placeholder="An app to keep track of my customers"
            />
            <ButtonWord isPrimary disabled>
              Generate
            </ButtonWord> */}
          </div>
        </div>
        {error && <div style={{ width: 400 }}>{error?.message}</div>}
      </ModalContent>
    );
  }

  let groupNamesToUse = groupNames;
  if (!groupNamesToUse?.includes("")) {
    groupNamesToUse = ["", ...groupNamesToUse];
  }

  if (modalState === "columns") {
    modalContent = (
      <ModalContent>
        {stepsNav}
        <Title>Configure fields</Title>
        <div style={{ overflow: "auto", paddingBottom: 60 }}>
          <div>
            <TableGroupName>App name *</TableGroupName>
            <StyledInput
              style={{ width: 300, backgroundColor: "white" }}
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="App name"
            />
            <TableGroupName>App description (optional)</TableGroupName>
            <StyledTextArea
              style={{ width: 300, backgroundColor: "white" }}
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="App description"
            />
          </div>
          {groupNamesToUse.map(groupName => {
            let groupColumns = columns.filter(
              column => column.groupName === groupName
            );
            // console.log({ groupName, groupColumns, columns });

            let tableColumns = TABLE_COLUMNS;
            if (groupName === "File Templates") {
              tableColumns = FILE_TABLE_COLUMNS;
            }

            return (
              <div>
                <TableGroupName>
                  {groupName || "Record ID"}
                  {groupName && (
                    <StyledTrashIcon onClick={() => removeGroup(groupName)} />
                  )}
                </TableGroupName>
                <Table style={{ width: "100%" }}>
                  <thead>
                    {tableColumns.map(column => (
                      <Th key={column.value}>{column.label}</Th>
                    ))}
                    <Th />
                  </thead>
                  <tbody>
                    {groupColumns.map((column, index) => {
                      const hasColumnBeenCreated =
                        existingPipelineConfig?.sourceTables?.[0]?.columns?.find(
                          col => col?.name === column?.name
                        );

                      if (column?.type === "TABLE") {
                        const addSubColumn = () => {
                          const newColumns = cloneDeep(columns);
                          const columnToEdit = newColumns.find(
                            col => col.id === column.id
                          );
                          columnToEdit.tableColumns = [
                            ...(columnToEdit.tableColumns || []),
                            {
                              id: uuidv4(),
                              name: "",
                              description: "",
                              groupName: "",
                              actionType: "extraction",
                              type: "TEXT",
                            },
                          ];
                          setColumns(newColumns);
                        };

                        return (
                          <>
                            <TableRow
                              isEditingRestricted={hasColumnBeenCreated}
                              isFileRow={groupName === "File Templates"}
                              key={column?.id}
                              columns={columns}
                              setColumns={setColumns}
                              column={column}
                            />
                            <tr>
                              <Td
                                style={{
                                  paddingLeft: "40px",
                                  backgroundColor: ORANGE,
                                  fontWeight: 500,
                                }}
                                colSpan={5}
                              >
                                Set up columns for "{column?.name}"
                              </Td>
                            </tr>
                            {column?.tableColumns?.map(
                              (subColumn, subIndex) => (
                                <TableRow
                                  isEditingRestricted={hasColumnBeenCreated}
                                  isIndented
                                  key={subColumn?.id}
                                  columns={column?.tableColumns}
                                  setColumns={newSubColumns => {
                                    const newColumns = cloneDeep(columns);
                                    const columnToEdit = newColumns.find(
                                      col => col.id === column.id
                                    );
                                    columnToEdit.tableColumns = newSubColumns;
                                    setColumns(newColumns);
                                  }}
                                  column={subColumn}
                                />
                              )
                            )}
                            {!hasColumnBeenCreated && (
                              <tr>
                                <Td style={{ paddingLeft: "40px" }}>
                                  <StyledPlusIcon onClick={addSubColumn} />
                                </Td>
                              </tr>
                            )}
                          </>
                        );
                      }

                      return (
                        <TableRow
                          isEditingRestricted={hasColumnBeenCreated}
                          isFileRow={groupName === "File Templates"}
                          key={column.id}
                          columns={columns}
                          setColumns={setColumns}
                          column={column}
                        />
                      );
                    })}
                    {groupName && (
                      <tr>
                        <Td style={{ border: "none" }}>
                          <StyledPlusIcon
                            onClick={() => {
                              setColumns([
                                ...columns,
                                {
                                  id: uuidv4(),
                                  name: "",
                                  description: "",
                                  actionType: "extraction",
                                  groupName,
                                  type: "TEXT",
                                },
                              ]);
                            }}
                          />
                        </Td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            );
          })}
          <div
            style={{
              paddingTop: 40,
              display: "flex",
              gap: 10,
              alignItems: "center",
              width: 300,
            }}
          >
            <StyledInput
              style={{ backgroundColor: "white" }}
              value={newGroupName}
              onChange={e => setNewGroupName(e.target.value)}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  addNewGroup();
                }
              }}
              placeholder="New Group Name"
            />
            <ButtonWord disabled={!newGroupName} onClick={addNewGroup}>
              Add
            </ButtonWord>
          </div>
        </div>

        <BottomButtons>
          <SaveTemplateModal
            name={name}
            description={description}
            columns={columns}
          />
          <NextButton
            disabled={!columns?.length || isLoading || !name}
            onClick={onClickDone}
          >
            Done
          </NextButton>
        </BottomButtons>
      </ModalContent>
    );
  }

  const LayoutComponent = pipelineConfigId === "new" ? LayoutNew : LayoutApp;

  return <LayoutComponent>{modalContent}</LayoutComponent>;
};

export default AppSettingsPage;
