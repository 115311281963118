import { cloneDeep, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { postAndStreamResponse } from "api/api-http-methods";
import { getWordDoc, patchWordDoc } from "api/backend/wordDocsEndpoints";
import DocArea from "components/DocArea";
import ButtonWord from "components/ui/ButtonWord";
import { getBlocksFromDoc, getDocFromBlocks } from "utils/word-coversion";
import { TEXT_WITH_TABLE } from "utils/word-utils-refactor";
import DocToolbar from "components/DocToolbar";

const GEN_CONFIG = {
  do_sample: false,
  max_new_tokens: 100,
};

const Container = styled.div`
  background-color: white;
  height: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const OuterContainer = styled.div`
  padding: 20px;
  background-color: #131718;
  height: 100vh;
`;

const TopBar = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const StyledDocArea = styled(DocArea)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const PromptContainer = styled.div`
  position: fixed;
  left: 40px;
  top: 100px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`;

const TextArea = styled.textarea`
  grid-column: span 2;
  height: 100px;
  font-family: "Montserrat", sans-serif;
  resize: none;
`;

const Debug = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  width: 300px;
`;

const ToolbarContainer = styled.div`
  position: fixed;
  top: 80px;
  left: 50vw;
  transform: translateX(-50%);
  background-color: white;

  width: 900px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}

const getPayloadFromDoc = (doc, prompt) => {
  const { blocks, cursor } = getBlocksFromDoc(doc);
  const payloadBlocks = cloneDeep(blocks);
  payloadBlocks.splice(cursor.blockIndex, 0, { text: prompt, isQuery: true });

  const payload = {
    blocks: payloadBlocks,
    cursor: {
      blockIndex: cursor.blockIndex,
      letterIndex: prompt?.length - 1,
    },
    sources: [],
    generation_config: GEN_CONFIG,
  };

  return payload;
};

const WordDocPageRefactor = () => {
  const { wordDocId } = useParams();
  const [prompt, setPrompt] = useState("");
  const [doc, setDoc] = useState(null);
  const [wordDoc, setWordDoc] = useState(null);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);

    setWordDoc(data);
    setDoc({
      ...getDocFromBlocks(data?.content?.blocks),
      // text: TEXT_WITH_TABLE,
    });
  };

  const doSaveDoc = async () => {
    const { blocks } = getBlocksFromDoc(doc);
    const newWordDoc = cloneDeep(wordDoc);
    newWordDoc.content.blocks = blocks;
    const { data } = await patchWordDoc(wordDocId, {}, newWordDoc);

    setWordDoc(data);
    setDoc(getDocFromBlocks(data?.content?.blocks));
  };

  const doSendQuery = async () => {
    const { error } = await postAndStreamResponse({
      url: `${BASE_URL}/bz-api/v1/ai/streamed-chat-queries`,
      reqBody: getPayloadFromDoc(doc, prompt),
      abortController: {},
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        setDoc(getDocFromBlocks(data?.blocks, data?.cursor));
      },
    });

    setPrompt("");
  };

  return (
    <OuterContainer>
      <Container>
        <TopBar>
          <ButtonWord onClick={doSaveDoc}>Save</ButtonWord>
        </TopBar>
        <ToolbarContainer>
          <DocToolbar doc={doc} onDocChange={newDoc => setDoc(newDoc)} />
        </ToolbarContainer>

        <PromptContainer
          style={{
            visibility: isNil(doc?.selStart) ? "hidden" : "visible",
          }}
        >
          <TextArea
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
            placeholder="Prompt"
          />
          <ButtonWord onClick={doSendQuery} disabled={!prompt}>
            Send
          </ButtonWord>
          <ButtonWord>Cancel</ButtonWord>
        </PromptContainer>
        <StyledDocArea doc={doc} onDocChange={newDoc => setDoc(newDoc)} />
        <Debug>
          char: {JSON.stringify(doc?.text?.[doc?.selStart])}
          <br />
          index: {doc?.selStart}
          <br />
          {JSON.stringify(doc?.text?.[doc?.selStart - 1])}
          <mark>{JSON.stringify(doc?.text?.[doc?.selStart])}</mark>
          {JSON.stringify(doc?.text?.[doc?.selStart + 1])}
          {/* <br />
          {JSON.stringify(doc?.text)} */}
        </Debug>
      </Container>
    </OuterContainer>
  );
};

export default WordDocPageRefactor;
