import { cloneDeep } from "lodash";
import {
  C_START,
  R_START,
  TABLE_CHARS,
  T_END,
  T_START,
} from "utils/word-utils-refactor";

const EMPTY_BLOCK = {
  text: "",
  styles: [],
};

export const getBlocksFromDoc = (doc = { text: "", styles: [] }) => {
  let blocks = [];

  let block = { ...EMPTY_BLOCK };
  let cursor = { blockIndex: 0, letterIndex: 0 };

  let i = 0;
  let blockIndex = 0;

  let rowIndex = -1;
  let columnIndex = -1;
  let isInTable = false;

  while (i < doc.text.length) {
    if (i === doc?.selStart) {
      cursor.blockIndex = blockIndex;
    }

    const char = doc.text[i];

    if (char === T_START) {
      rowIndex = -1;
      columnIndex = -1;
      isInTable = true;
    }

    if (char === T_END) {
      isInTable = false;
    }

    if (char === R_START) {
      rowIndex += 1;
      columnIndex = -1;
    }

    if (char === C_START) {
      columnIndex += 1;
    }

    if (char === C_START) {
      blockIndex++;
      blocks.push(block);
      block = { ...EMPTY_BLOCK, isTableCell: true, rowIndex, columnIndex };
      i++;
      continue;
    }

    if (char === "\n" || char === T_END) {
      blockIndex++;
      blocks.push(block);
      block = { ...EMPTY_BLOCK };
      if (isInTable) {
        block = { ...block, isTableCell: true, rowIndex, columnIndex };
      }
      i++;
      continue;
    }

    if (TABLE_CHARS.includes(char)) {
      i++;
      continue;
    }

    block.text += char;
    i++;
  }
  blocks.push(block);

  return { blocks, cursor };
};

export const getSelStartFromCursor = (
  blocks = [],
  cursor = { blockIndex: 0, letterIndex: 0 }
) => {
  let selStart = 0;

  let blockIndex = 0;
  while (blockIndex < cursor.blockIndex) {
    if (blocks[blockIndex]?.isQuery) {
      blockIndex++;
      continue;
    }

    selStart += blocks[blockIndex].text.length + 1;
    blockIndex++;
  }

  selStart += cursor.letterIndex;

  return selStart;
};

const getStyleAt = (blocks, blockIndex, iBlock = 0) => {
  const block = blocks[blockIndex];
  if (!block?.queryId) {
    return null;
  }

  const queryBlock = blocks?.find(
    b => b?.isQuery && b.queryId === block.queryId
  );
  return { queryId: block.queryId, prompt: queryBlock?.text };
};

const getTableChars = (blocks, blockIndex) => {
  const block = blocks?.[blockIndex];
  const prevBlock = blocks?.[blockIndex - 1];

  if (!block?.isTableCell && prevBlock?.isTableCell) {
    return T_END;
  }

  if (!block?.isTableCell && !prevBlock?.isTableCell) {
    return "";
  }

  if (block?.isTableCell && !prevBlock?.isTableCell) {
    return T_START + R_START + C_START;
  }

  if (block?.rowIndex !== prevBlock?.rowIndex) {
    return R_START + C_START;
  }

  if (block?.columnIndex !== prevBlock?.columnIndex) {
    return C_START;
  }

  return "";
};

const shouldAddNewLineAtEndOfBlock = (blocks, blockIndex) => {
  // dont add for the very last block
  if (blockIndex === blocks.length - 1) {
    return false;
  }

  const block = blocks?.[blockIndex];
  const nextBlock = blocks?.[blockIndex + 1];

  // dont add right before table
  if (!block?.isTableCell && nextBlock?.isTableCell) {
    return false;
  }

  // if last block in cell
  if (
    block?.isTableCell &&
    (nextBlock?.rowIndex !== block?.rowIndex ||
      nextBlock?.columnIndex !== block?.columnIndex)
  ) {
    return false;
  }

  return true;
};

export const getDocFromBlocks = (
  blocks = [],
  cursor = { blockIndex: 0, letterIndex: 0 }
) => {
  let doc = {
    text: "",
    styles: [],
    selStart: getSelStartFromCursor(blocks, cursor),
    selEnd: getSelStartFromCursor(blocks, cursor),
  };

  let blockIndex = 0;
  let i = 0;

  while (blockIndex < blocks.length) {
    const block = blocks[blockIndex];

    if (block?.isQuery) {
      blockIndex++;
      continue;
    }

    // check if table cell block
    if (getTableChars(blocks, blockIndex)) {
      const tChars = getTableChars(blocks, blockIndex);
      doc.text += tChars;
      i += tChars.length;
    }

    // go through text in block
    let iBlock = 0;
    while (iBlock < block.text.length) {
      doc.text += block.text[iBlock];
      doc.styles[i] = getStyleAt(blocks, blockIndex, iBlock);
      i++;
      iBlock++;
    }

    if (shouldAddNewLineAtEndOfBlock(blocks, blockIndex)) {
      doc.text += "\n";
      i++;
    }
    blockIndex++;
  }

  return doc;
};
