import { cloneDeep, set, uniq } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  deleteSigmasV2,
  getPipelineConfig,
  getSigmasV2,
  postSigmasFillupData,
  postSigmasV2,
  putPipelineConfig,
  putSigmasV2,
} from "api/backend/fileSystemEndpoints";
import AddSigmaRecordModalNew from "components/AddSigmaRecordModalNew";
import GridDraggable from "components/GridDraggable";
import LayoutApp from "components/LayoutApp";
import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon, TickIcon } from "components/ui/Icons";
import {
  BinIcon,
  EllipsisIcon,
  EyeWithDocIcon,
  MoveIcon,
  PencilIconDetailed,
  ResetIcon,
} from "components/IconsNew";
import { getColorFromIndex, isTruthy } from "utils/common";
import WordDocArea from "components/WordDocArea";
import { getWordDoc, postWordDoc } from "api/backend/wordDocsEndpoints";
import TooltipClick from "components/ui/TooltipClick";
import JobsTooltipTrigger from "components/JobsTooltipTrigger";
import ReferenceDebugModal from "components/ReferenceDebugModal";
import ExcelArea from "components/ExcelArea";
import SlideDocArea from "components/SlideDocArea";
import RecordFieldInput from "components/RecordFieldInput";
import RecordFieldInputTable from "components/RecordFieldInputTable";
import FieldReferenceModal from "components/FieldReferenceModal";
import { postSigmaRerunExtractionJobs } from "api/backend/sigmaEndpoints";
import RecordFilesModal from "components/RecordFilesModal";
import useFlowUrl from "hooks/useFlowUrl";

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  grid-template-columns: 270px 1fr;
  height: 100%;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const SideNav = styled.div`
  z-index: 1;
  overflow: auto;
  display: grid;
  grid-template-rows: 54px 1fr auto;
  position: relative;
`;

const GroupCard = styled.div`
  background-color: #ffffff;
  padding: 0px;
  border-radius: 10px;
  height: 100%;
  align-content: start;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  border: 1px solid #f1f1f1;
`;

const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  background-color: ${props => props.color || "#888888"}DE;
  padding: 0px 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  white-space: nowrap;
  grid-column: span 2;
  color: white;
  height: 42px;
  display: grid;
  align-items: center;
`;

const GroupFields = styled.div`
  padding: 10px;
  align-content: start;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`;

const StyledFieldReferenceModal = styled(FieldReferenceModal)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const StyledRecordFilesModal = styled(RecordFilesModal)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const NameFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  :hover {
    ${StyledFieldReferenceModal} {
      opacity: 1;
    }
    ${StyledRecordFilesModal} {
      opacity: 1;
    }
  }
`;

const Field = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
  :hover {
    ${StyledFieldReferenceModal} {
      opacity: 1;
    }
    ${StyledRecordFilesModal} {
      opacity: 1;
    }
  }
`;

const FieldLabel = styled.div`
  font-weight: 600;
  color: ${props => props.color || "#000000"};
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    height: 10px;
  }
`;

const SideNavItem = styled.div`
  padding: 12px;
  margin: 5px 10px;
  border-radius: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  cursor: pointer;
  ${props => props.isSelected && "background-color: #E8ECEFBF;"}
  :hover {
    background-color: #e8ecefbf;
  }
`;

const SimpleButton = styled.button`
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #b9b9b9;
  background-color: transparent;
  outline: none;
  font-weight: 500;

  color: #b9b9b9;
  svg {
    height: 14px;
    fill: #b9b9b9;
  }

  :hover {
    background-color: #b9b9b9;
    color: white;
    svg {
      fill: white;
    }
  }

  ${props =>
    props.isPrimary &&
    `
    background-color: #0191FF; 
    color: white;
    border: 1px solid #0191FF;
    :hover {
      background-color: #0191FF;
      color: white;
    }
    `}
`;

const TopBar = styled.div`
  position: relative;
  height: 54px;
  width: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto auto;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
  box-shadow: 0px 12px 40px -12px #0000000f;

  border-left: 1px solid #e8ecef;
  border-bottom: 1px solid #e8ecef;
`;

const ZoomBtn = styled(ButtonWord)`
  padding: 4px;
  line-height: 8px;
  svg {
    height: 8px;
    fill: white;
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 10px;
`;

const Tab = styled.div`
  padding: 4px 16px;
  border-bottom: 2px solid ${props => (props.isSelected ? "#0191ff" : "white")};
  cursor: pointer;
`;

const GridContainer = styled.div`
  background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%);
  box-shadow: 0px 24px 60px 0px #0000001a;
  border-left: 1px solid #e8ecef;
  overflow: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const AppNameText = styled.div`
  font-weight: 600;
  color: #b9b9b9;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilterContainer = styled.div`
  padding: 10px;
  background-color: white;
  position: sticky;
  top: 0;
`;

const FilterInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const ToggleDiv = styled.div`
  border: 1px solid #424242;
  color: #424242;
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: white;
  svg {
    fill: #424242;
  }
  :hover {
    color: ${props => props.theme.color.primary};
    border-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }

  ${props =>
    props.isSelected &&
    `
    background-color: #424242; 
    color: white;
    svg {
      fill: white;
    }
    `}
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid #dddddd;
  height: 0px;
  margin-bottom: 0px;
  width: 100%;
  grid-column: span 2;
`;

const HasMoreBtn = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 8px 0;
  padding-right: 0px;
  gap: 8px;
  align-items: start;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  cursor: pointer;
  opacity: 0.6;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8d8d8d;
  font-weight: 500;
  text-decoration: none;
  margin-left: 20px;
  margin-bottom: 10px;

  :hover {
    opacity: 1;
  }
  svg {
    color: #0191ff;
  }
`;

const DATA_TYPE_TO_INPUT_TYPE = {
  TEXT: "text",
  NUMBER: "number",
  DATETIME: "datetime-local",
  TABLE: "table",
  CATEGORICAL: "select",
};

const INITIAL_COLS = 2;
export const INITIAL_W = 24;
export const INITIAL_H = 14;

const getKeyHeight = (groupName, columns) => {
  const groupColumns = columns.filter(col => col?.name?.startsWith(groupName));
  const nonTableCount = groupColumns?.filter(
    col => col?.type !== "TABLE"
  )?.length;
  const tableCount = groupColumns?.filter(col => col?.type === "TABLE")?.length;

  const h = (nonTableCount * 3) / 2 + tableCount * 9;
  return Math.ceil(h) + 3;
};

export const getInitialLayoutFromColumns = ({ keys = [], columns = [] }) => {
  let layout = {};

  let x = 1;
  let y = 1;
  let w = INITIAL_W;
  let h = INITIAL_H;

  keys?.filter(isTruthy).forEach(key => {
    layout[key] = { x, y, w, h: getKeyHeight(key, columns) };
    x += w + 1;
    if (x >= INITIAL_COLS * w) {
      x = 1;
      y += h + 1;
    }
  });

  return layout;
};

const doesKeyOccupySpace = (keyPosition, x, y, w, h) => {
  const { x: keyX, y: keyY, w: keyW, h: keyH } = keyPosition;
  return keyX < x + w && keyX + keyW > x && keyY < y + h && keyY + keyH > y;
};

const isSpaceTaken = (layout, x, y, w, h) => {
  const keys = Object.keys(layout);
  return keys?.some(key => doesKeyOccupySpace(layout[key], x, y, w, h));
};

export const getAvailableSpace = layout => {
  let x = 1;
  let y = 1;
  let w = INITIAL_W;
  let h = INITIAL_H;

  while (isSpaceTaken(layout, x, y, w, h)) {
    x += 1;
    y += 1;

    if (x >= 1000 && y >= 1000) {
      return { x: 1, y: 1, w, h };
    }
  }

  return { x, y, w, h };
};

const AppRecordPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pipelineConfigId, selectedRecordIdStr } = useParams();
  const [pipelineConfig, setPipelineConfig] = useState(null);
  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [fields, setFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingLayout, setIsEditingLayout] = useState(false);
  const [isFillingUp, setIsFillingUp] = useState(false);
  const [filterStr, setFilterStr] = useState("");
  const [isEditingValues, setIsEditingValues] = useState(false);
  const [gridSF, setGridSF] = useState(1);
  const flowUrl = useFlowUrl();
  const [pageSize, setPageSize] = useState(20);

  const tableId = pipelineConfig?.sourceTables?.[0]?.id;
  const selectedRecordId = parseInt(selectedRecordIdStr);

  const selectedRecord = sigmaRecords?.find(
    record => record.id === selectedRecordId
  );

  const [selectedTab, setSelectedTab] = useState("fields");

  useEffect(() => {
    setGridSF(pipelineConfig?.meta?.gridSF || 1);
  }, [JSON.stringify(pipelineConfig?.meta)]);

  const doPutGridSF = async newGridSf => {
    const { data: configWithNewGridSF } = await putPipelineConfig(
      pipelineConfigId,
      {},
      {
        ...pipelineConfig,
        meta: {
          ...(pipelineConfig?.meta || {}),
          gridSF: newGridSf,
        },
      }
    );
    setPipelineConfig(configWithNewGridSF);
  };

  useEffect(() => {
    if (searchParams?.get("isEditMode") === "true") {
      setIsEditingValues(true);
      navigate(`/apps/${pipelineConfigId}/records/${selectedRecordIdStr}`);
    }
  }, [searchParams?.get("isEditMode")]);

  // FETCHING
  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  useEffect(() => {
    if (!pipelineConfigId || !tableId) {
      return;
    }
    doPopulateSigmaRecords(pipelineConfigId, tableId, pageSize);
    const intervalId = setInterval(() => {
      doPopulateSigmaRecords(pipelineConfigId, tableId, pageSize);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [pipelineConfigId, tableId, selectedRecordIdStr, pageSize]);

  const doFillUp = async () => {
    setIsFillingUp(true);
    await postSigmasFillupData(selectedRecordId, {
      pipelineId: pipelineConfigId,
      tableName: tableId,
      gpt_assistance: true,
    });
    setIsFillingUp(false);
  };

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  const doPopulateSigmaRecords = async (pipelineId, tableName, limit = 10) => {
    const { data } = await getSigmasV2({
      pipelineId,
      tableName,
      limit,
    });
    setSigmaRecords(data || []);
    if (!selectedRecordIdStr) {
      navigate(`/apps/${pipelineConfigId}/records/${data?.[0]?.id}`);
    }
  };

  const doPutSelectedSigmaRecord = async payloadFields => {
    setIsLoading(true);
    const { data } = await putSigmasV2(
      selectedRecordId,
      {},
      {
        ...selectedRecord,
        fields: payloadFields,
      }
    );
    setIsLoading(false);
    setFields(data?.fields || {});
    doPopulateSigmaRecords(pipelineConfigId, tableId);
  };

  const doPostEmptyRecord = async () => {
    setIsLoading(true);

    const wordDocColumns = pipelineConfig?.sourceTables?.[0]?.columns?.filter(
      column => column?.groupName === "File Templates"
    );
    let wordDocFields = {};
    const wordDocPromises = wordDocColumns?.map(async column => {
      const { data: templateWordDoc } = await getWordDoc(column?.description);
      const { data: wordDoc } = await postWordDoc(
        {},
        {
          fileName: column?.name,
          content: templateWordDoc?.content,
        }
      );
      wordDocFields[column.name] = {
        value: wordDoc.id,
      };
    });
    await Promise.all(wordDocPromises);

    const { data } = await postSigmasV2(
      {},
      {
        pipelineId: pipelineConfig?.id,
        tableName: pipelineConfig?.sourceTables?.[0]?.id,
        fields: {
          [recordNameColumn?.name]: {
            value: "Blank Record",
          },
          ...wordDocFields,
        },
      }
    );
    setIsLoading(false);
    navigate(`/apps/${pipelineConfigId}/records/${data?.id}?isEditMode=true`);
  };

  const doPutPipelineConfigLayout = async newLayout => {
    const { data: configWithNewLayout } = await putPipelineConfig(
      pipelineConfigId,
      {},
      {
        ...pipelineConfig,
        meta: {
          ...pipelineConfig.meta,
          layout: newLayout,
        },
      }
    );
    setPipelineConfig(configWithNewLayout);
  };

  const doDeleteSelectedSigmaRecord = async () => {
    await deleteSigmasV2(selectedRecordId, {
      tableName: tableId,
      pipelineID: pipelineConfigId,
    });
    const nextId = sigmaRecords?.find(r => r.id !== selectedRecordId)?.id;
    await doPopulateSigmaRecords(pipelineConfigId, tableId);
    navigate(`/apps/${pipelineConfigId}/records/${nextId}`);
  };

  const onClickTick = async () => {
    doPutSelectedSigmaRecord(fields);
    await putPipelineConfig(pipelineConfigId, {}, pipelineConfig);
    setIsEditingValues(false);
  };

  const onClickCross = () => {
    setFields(selectedRecord?.fields || {});
    setIsEditingValues(false);
  };

  // USER UPDATES
  useEffect(() => {
    setFields(selectedRecord?.fields || {});
  }, [JSON.stringify(selectedRecord)]);

  const columns = pipelineConfig?.sourceTables?.[0]?.columns || [];
  const groupNames = uniq(columns?.map(column => column.groupName));
  const recordNameColumn = columns?.find(column => !column?.groupName);

  // sort by createdAt
  const sigmaRecordsMostRecentFirst = sigmaRecords
    .sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
    ?.filter(record => {
      return (
        record?.fields?.[recordNameColumn?.name]?.value
          ?.toLowerCase()
          ?.includes(filterStr?.toLowerCase()) ||
        !record?.fields?.[recordNameColumn?.name]?.value
      );
    });

  // const initialLayout = getInitialLayoutFromColumns({
  //   keys: groupNames?.filter(groupName => !!groupName),
  //   columns,
  // });

  const wordDocColumnNames = columns
    ?.filter(column => column?.groupName === "File Templates")
    ?.map(column => column?.name);

  const onChangeRecordInput = (newValue, fieldName, fieldPlotState = null) => {
    const newFields = cloneDeep(fields);
    set(newFields, `${fieldName}.value`, newValue);
    setFields(newFields);
  };

  const onChangePlotState = (fieldName, fieldPlotState) => {
    const newPlotStateMap = {
      ...(pipelineConfig?.meta?.plotStateMap || {}),
      [fieldName]: fieldPlotState,
    };
    const newPipelinConfig = cloneDeep(pipelineConfig);
    set(newPipelinConfig, "meta.plotStateMap", newPlotStateMap);
    setPipelineConfig(newPipelinConfig);
  };

  const doRerunRecord = async () => {
    setIsLoading(true);
    await postSigmaRerunExtractionJobs(
      {},
      {
        ids: [selectedRecord?.extractionJobId],
      }
    );
    await doPopulateSigmaRecords(pipelineConfigId, tableId);
    setIsLoading(false);
  };

  let fieldCount = 0;
  const containsFileColumns = columns?.some(
    col => col?.groupName === "File Templates"
  );

  let linkToDocForName = (
    <StyledFieldReferenceModal
      field={fields?.[recordNameColumn?.name]}
      column={recordNameColumn}
      isDisabled={!fields?.[recordNameColumn?.name]?.reference}
      reference={fields?.[recordNameColumn?.name]?.reference}
    >
      <EyeWithDocIcon
        style={{
          fill: fields?.[recordNameColumn?.name]?.reference
            ? "#0191ff"
            : "#ccc",
          marginTop: 2,
        }}
      />
    </StyledFieldReferenceModal>
  );
  if (!fields?.[recordNameColumn?.name]?.reference) {
    linkToDocForName = (
      <StyledRecordFilesModal record={selectedRecord} columns={columns}>
        <EyeWithDocIcon style={{ fill: "#ccc", cursor: "pointer" }} />
      </StyledRecordFilesModal>
    );
  }

  return (
    <LayoutApp>
      <Container isDisabled={isLoading}>
        <SideNav>
          <div style={{ padding: 20, borderBottom: "1px solid #E8ECEF" }}>
            <AppNameText>{pipelineConfig?.name}</AppNameText>
          </div>
          <div style={{ overflow: "auto" }}>
            <FilterContainer>
              <FilterInput
                placeholder="Filter records"
                value={filterStr}
                onChange={e => setFilterStr(e.target.value)}
              />
            </FilterContainer>
            {sigmaRecordsMostRecentFirst.map(record => (
              <SideNavItem
                isSelected={record.id === selectedRecordId}
                onClick={() =>
                  navigate(`/apps/${pipelineConfigId}/records/${record?.id}`)
                }
              >
                <div>
                  {record?.fields?.[recordNameColumn?.name]?.value || record.id}
                </div>
                {record.id === selectedRecordId && (
                  <div style={{ paddingTop: 10, display: "flex", gap: 10 }}>
                    <AddSigmaRecordModalNew
                      recordId={record.id}
                      style={{ width: "max-content" }}
                      trigger={<SimpleButton isPrimary>Ingest</SimpleButton>}
                      pipelineConfig={pipelineConfig}
                    />
                    <SimpleButton onClick={doRerunRecord}>Re-run</SimpleButton>
                    {isEditingValues ? (
                      <>
                        <SimpleButton onClick={onClickTick}>
                          <TickIcon />
                        </SimpleButton>
                        <SimpleButton onClick={onClickCross}>
                          <CrossIcon />
                        </SimpleButton>
                      </>
                    ) : (
                      <SimpleButton onClick={() => setIsEditingValues(true)}>
                        <PencilIconDetailed />
                      </SimpleButton>
                    )}
                    {!isEditingValues && (
                      <SimpleButton onClick={doDeleteSelectedSigmaRecord}>
                        <BinIcon />
                      </SimpleButton>
                    )}
                  </div>
                )}
              </SideNavItem>
            ))}
            {pageSize <= sigmaRecordsMostRecentFirst?.length && (
              <HasMoreBtn
                onClick={() => {
                  const newPageSize = pageSize + 10;
                  setPageSize(newPageSize);
                }}
              >
                Load more
              </HasMoreBtn>
            )}

            {/* <StyledPlusIcon
              onClick={doPostEmptyRecord}
              style={{ margin: "8px 20px" }}
            /> */}
          </div>

          <div style={{ borderTop: "1px solid #E8ECEF", padding: "10px 20px" }}>
            <AddSigmaRecordModalNew
              doPostEmptyRecord={doPostEmptyRecord}
              trigger={
                <ButtonWord style={{ width: "100%", height: "38px" }} isPrimary>
                  + Add record
                </ButtonWord>
              }
              pipelineConfig={pipelineConfig}
            />
          </div>
        </SideNav>
        <div style={{ width: "100%" }}>
          <TopBar>
            <NameFieldContainer>
              <RecordFieldInput
                style={{ fontSize: 18, fontWeight: 600 }}
                value={fields?.[recordNameColumn?.name]?.value}
                isEditMode={isEditingValues}
                onChange={e =>
                  onChangeRecordInput(e.target.value, recordNameColumn?.name)
                }
                rawValue={fields?.[recordNameColumn?.name]?.value}
              />

              {linkToDocForName}
              <ReferenceDebugModal
                reference={fields?.[recordNameColumn?.name]?.reference}
              />
            </NameFieldContainer>
            <div />
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <ZoomBtn
                onClick={() => {
                  setGridSF(1);
                  doPutGridSF(1);
                }}
              >
                <ResetIcon />
              </ZoomBtn>
              <ZoomBtn
                onClick={() => {
                  const newGridSf = gridSF - 0.1;
                  setGridSF(newGridSf);
                  doPutGridSF(newGridSf);
                }}
              >
                -
              </ZoomBtn>
              <ZoomBtn
                onClick={() => {
                  const newGridSf = gridSF + 0.1;
                  setGridSF(newGridSf);
                  doPutGridSF(newGridSf);
                }}
              >
                +
              </ZoomBtn>
            </div>
            <ButtonWord
              onClick={() => window?.open(flowUrl, "_blank")}
              isPrimary
            >
              Model
            </ButtonWord>
            <RecordFilesModal record={selectedRecord} columns={columns}>
              <EyeWithDocIcon style={{ fill: "#0191ff", cursor: "pointer" }} />
            </RecordFilesModal>
            <TooltipClick
              position="bottom"
              tipContent={
                <div
                  style={{
                    display: "grid",
                    gap: 10,
                    border: "1px solid #ccc",
                    padding: 10,
                    width: 150,
                  }}
                >
                  <ButtonWord
                    disabled={isLoading || !selectedRecordId}
                    onClick={doDeleteSelectedSigmaRecord}
                  >
                    Delete
                  </ButtonWord>
                  <ButtonWord disabled={isFillingUp} onClick={doFillUp}>
                    Fill up
                  </ButtonWord>
                </div>
              }
            >
              <EllipsisIcon style={{ cursor: "pointer" }} />
            </TooltipClick>
            <JobsTooltipTrigger />
          </TopBar>
          {containsFileColumns && (
            <Tabs>
              <Tab
                isSelected={selectedTab === "fields"}
                onClick={() => setSelectedTab("fields")}
              >
                Fields
              </Tab>
              {wordDocColumnNames?.map(wordDocColumnName => (
                <Tab
                  isSelected={selectedTab === wordDocColumnName}
                  onClick={() => setSelectedTab(wordDocColumnName)}
                >
                  {wordDocColumnName?.replace("File Templates_", "")}
                </Tab>
              ))}
              {/* <Tab
                isSelected={selectedTab === "excel"}
                onClick={() => setSelectedTab("excel")}
              >
                excel
              </Tab>
              <Tab
                isSelected={selectedTab === "ppt"}
                onClick={() => setSelectedTab("ppt")}
              >
                ppt
              </Tab> */}
            </Tabs>
          )}
          <div
            style={{
              display: !["fields", "excel", "ppt"]?.includes(selectedTab)
                ? "block"
                : "none",
              height: "calc(100vh - 40px - 60px - 24px)",
              borderTop: "1px solid #ccc",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <WordDocArea
              wordDocId={selectedRecord?.fields?.[selectedTab]?.value}
              isFillingUp={isFillingUp}
            />
          </div>
          <div
            style={{
              display: selectedTab === "excel" ? "block" : "none",
              height: "calc(100vh - 40px - 60px - 18px)",
              borderTop: "1px solid #ccc",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {selectedTab === "excel" && (
              <ExcelArea excelModelId="66c87045ca6bb039caf9cb9f" />
            )}
          </div>
          <div
            style={{
              display: selectedTab === "ppt" ? "block" : "none",
              height: "calc(100vh - 40px - 60px - 18px)",
              borderTop: "1px solid #ccc",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {selectedTab === "ppt" && (
              <SlideDocArea slideDocId="65e8c619f751327fd7a210ee" />
            )}
          </div>
          <GridContainer
            style={{
              display: selectedTab === "fields" ? "block" : "none",
              overflow: "auto",
              width: "calc(100vw - 90px - 20px - 280px)",
              height: containsFileColumns
                ? "calc(100vh - 40px - 54px - 22px)"
                : "calc(100vh - 40px - 60px)",
            }}
          >
            <GridDraggable
              gridSF={gridSF}
              style={{
                overflow: "hidden",
                transformOrigin: "top left",
                transform: `scale(${gridSF})`,
                width: `${2000 / gridSF}px`,
                height: `${1300 / gridSF}px`,
              }}
              initialLayout={pipelineConfig?.meta?.layout}
              onDragEnd={newLayout => doPutPipelineConfigLayout(newLayout)}
              isEditingDisabled={!isEditingLayout}
            >
              {groupNames
                ?.filter(
                  groupName => !!groupName && groupName !== "File Templates"
                )
                ?.map((groupName, groupIndex) => (
                  <GroupCard key={groupName}>
                    <GroupTitle color={getColorFromIndex(groupIndex)}>
                      {groupName}
                    </GroupTitle>
                    <GroupFields>
                      {columns
                        ?.filter(column => column.groupName === groupName)
                        ?.map((column, colIndex) => {
                          const plotStateMap =
                            pipelineConfig?.meta?.plotStateMap || {};

                          fieldCount += 1;
                          if (column?.type === "TABLE") {
                            fieldCount += 1;
                          }

                          let linkToDoc = (
                            <StyledRecordFilesModal
                              record={selectedRecord}
                              columns={columns}
                            >
                              <EyeWithDocIcon
                                style={{ fill: "#ccc", cursor: "pointer" }}
                              />
                            </StyledRecordFilesModal>
                          );
                          if (fields?.[column.name]?.reference) {
                            linkToDoc = (
                              <StyledFieldReferenceModal
                                isDisabled={!fields?.[column.name]?.reference}
                                reference={fields?.[column.name]?.reference}
                                column={column}
                                field={fields?.[column.name]}
                              >
                                <EyeWithDocIcon
                                  style={{
                                    fill: fields?.[column.name]?.reference
                                      ? getColorFromIndex(groupIndex)
                                      : "#ccc",
                                    marginTop: 2,
                                  }}
                                />
                              </StyledFieldReferenceModal>
                            );
                          }

                          return (
                            <>
                              <Field
                                style={{
                                  gridColumn:
                                    column?.type === "TABLE"
                                      ? "span 2"
                                      : "auto",
                                }}
                              >
                                <FieldLabel
                                  color={getColorFromIndex(groupIndex)}
                                >
                                  {column.name?.replaceAll(`${groupName}_`, "")}
                                  {linkToDoc}
                                  <ReferenceDebugModal
                                    reference={fields?.[column.name]?.reference}
                                  />
                                </FieldLabel>
                                {column?.type === "TABLE" && (
                                  <RecordFieldInputTable
                                    groupColor={getColorFromIndex(groupIndex)}
                                    initialPlotState={
                                      plotStateMap?.[column?.name]
                                    }
                                    tableColumns={column?.tableColumns}
                                    value={fields?.[column.name]?.value}
                                    isEditMode={isEditingValues}
                                    onChangeValue={newValue =>
                                      onChangeRecordInput(newValue, column.name)
                                    }
                                    onChangePlotState={fieldPlotState =>
                                      onChangePlotState(
                                        column?.name,
                                        fieldPlotState
                                      )
                                    }
                                  />
                                )}
                                {column?.type !== "TABLE" && (
                                  <RecordFieldInput
                                    isEditMode={isEditingValues}
                                    type={
                                      DATA_TYPE_TO_INPUT_TYPE?.[column.type]
                                    }
                                    value={fields?.[column.name]?.value}
                                    options={column?.categories}
                                    onChange={e =>
                                      onChangeRecordInput(
                                        e.target.value,
                                        column.name
                                      )
                                    }
                                    rawValue={
                                      fields?.[column.name]?.rawValue || ""
                                    }
                                  />
                                )}
                              </Field>
                              {fieldCount % 2 === 0 && <HorizontalLine />}
                            </>
                          );
                        })}
                    </GroupFields>
                  </GroupCard>
                ))}
            </GridDraggable>
          </GridContainer>
        </div>

        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            display: selectedTab === "fields" ? "flex" : "none",
            gap: 10,
            zIndex: 1,
          }}
        >
          <ToggleDiv
            isSelected={isEditingLayout}
            onClick={() => setIsEditingLayout(!isEditingLayout)}
          >
            <MoveIcon />
            Rearrange
          </ToggleDiv>
          {/* <StyledButtonWord
            disabled={isLoading || !selectedRecordId}
            onClick={() => setIsEditingLayout(!isEditingLayout)}
          >
            {isEditingLayout ? <CrossIcon /> : <PencilIcon />}
          </StyledButtonWord> */}
        </div>
      </Container>
    </LayoutApp>
  );
};

export default AppRecordPage;
